/*
Template: EV Spark - Electric Vehicle & Charging Stations HTML Template
Version: 1.0.0
Author: potenzaglobalsolutions
Design and Developed by: potenzaglobalsolutions.com

NOTE: This is main stylesheet of template, This file contains the styling for the actual Template.
*/
/*==============================================
[  Table of contents  ]
================================================

 :: Typography
 :: Helper Classes
 :: Shortcodes
    - Accordion
    - Button
    - Countdown
    - Counter
    - Feature Info
		- Portfolio
		- Newsletter
		- Pie Chart
		- Product
    - Product List
    - Select
    - Service Info
    - Sidebar
    - Testimonial
 :: Header
   - Header Default
   - Header Style 02
   - Header Style 03
 :: Banner
   - Banner
   - Banner 02
   - Banner 03
 :: Layout
   - Section Title
   - Video
   - Keyframes
   - Clients
   - list
   - Inner Header
   - Pagination
 :: Blog
   - Blog
   - Blog Style 01
   - Blog Style 02
   - Blog Style 03
   - Blog Style single
 :: Shop
   - Product
   - Product Detail
    - Commentlist
    - Checkout
    - Cart
    - Shop Category
 :: Footer
   - Footer
   - Footer Style 02

======================================
[ End table content ]
======================================*/
/*****************************
    Helper Classes
*****************************/
.text-primary {
  color: #1962a3 !important; }

.text-secondary {
  color: #091c3a !important; }

.text-white {
  color: #ffffff; }

.text-dark {
  color: #091c3a !important; }

.text-light {
  color: #8d8c8c !important; }

.text-yellow {
  color: #ee9914 !important; }

.bg-primary {
  background: #1962a3 !important; }

.bg-dark {
  background: #091c3a !important; }

.bg-secondary {
  background: #091c3a !important; }

.bg-light {
  background: #f0f6fa !important; }

.bg-black {
  background: #000000; }

.bg-holder {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%; }

.bg-holder-bottom {
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  width: 100%; }

.space-ptb {
  padding: 100px 0; }

.space-pt {
  padding: 100px 0 0; }

.space-pb {
  padding: 0 0 100px; }

.bg-overlay-half-bottom {
  position: relative; }

.bg-overlay-black-1 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-1:before {
    background: rgba(0, 0, 0, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-2 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-2:before {
    background: rgba(0, 0, 0, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-3 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-3:before {
    background: rgba(0, 0, 0, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-4 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-4:before {
    background: rgba(0, 0, 0, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-5 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-5:before {
    background: rgba(0, 0, 0, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-6 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-6:before {
    background: rgba(0, 0, 0, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-black-7 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-7:before {
    background: rgba(0, 0, 0, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-8 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-8:before {
    background: rgba(0, 0, 0, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-black-9 {
  position: relative;
  z-index: 1; }
  .bg-overlay-black-9:before {
    background: rgba(0, 0, 0, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-secondary-1 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-1:before {
    background: rgba(9, 28, 58, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-secondary-2 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-2:before {
    background: rgba(9, 28, 58, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-secondary-3 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-3:before {
    background: rgba(9, 28, 58, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-secondary-4 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-4:before {
    background: rgba(9, 28, 58, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-secondary-5 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-5:before {
    background: rgba(9, 28, 58, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-secondary-6 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-6:before {
    background: rgba(9, 28, 58, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

.bg-overlay-secondary-7 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-7:before {
    background: rgba(9, 28, 58, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-secondary-8 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-8:before {
    background: rgba(9, 28, 58, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-secondary-9 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-9:before {
    background: rgba(9, 28, 58, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }

.bg-overlay-secondary-10 {
  position: relative;
  z-index: 1; }
  .bg-overlay-secondary-10:before {
    background: rgba(38, 115, 248, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1; }

@media (max-width: 1200px) {
  .space-ptb {
    padding: 80px 0; }
  .space-pt {
    padding: 80px 0 0; }
  .space-pb {
    padding: 0 0 80px; } }

@media (max-width: 991px) {
  .space-ptb {
    padding: 60px 0; }
  .space-pt {
    padding: 60px 0 0; }
  .space-pb {
    padding: 0 0 60px; } }

@media (max-width: 767px) {
  .space-ptb {
    padding: 50px 0; }
  .space-pt {
    padding: 50px 0 0; }
  .space-pb {
    padding: 0 0 50px; } }

@media (max-width: 575px) {
  .space-ptb {
    padding: 40px 0; }
  .space-pt {
    padding: 40px 0 0; }
  .space-pb {
    padding: 0 0 40px; } }

/*****************************
    Typography
*****************************/
body {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 1.5;
  color: #8d8c8c;
  overflow-x: hidden;
  font-weight: 300; }

a {
  outline: medium none !important;
  color: #1962a3;
  transition: all 0.3s ease-in-out;
  text-decoration: none !important; }
  a:focus {
    color: #1962a3;
    text-decoration: none !important; }
  a:hover {
    color: #1962a3;
    text-decoration: none !important; }

input {
  outline: medium none !important;
  color: #1962a3; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Outfit", sans-serif;
  font-weight: normal;
  color: #091c3a;
  margin-top: 0px;
  font-weight: 400; }
  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: inherit; }

label {
  font-weight: normal; }

h1 {
  font-size: 92px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.1; }

h2 {
  font-size: 48px;
  font-weight: 500; }

h3 {
  font-size: 24px;
  font-weight: 500; }

h4 {
  font-size: 22px;
  font-weight: 500; }

h5 {
  font-size: 20px;
  font-weight: 500; }

h6 {
  font-size: 18px;
  font-weight: 500; }

p {
  line-height: 1.5; }

*::-moz-selection {
  background: #1962a3;
  color: #ffffff;
  text-shadow: none; }

::-moz-selection {
  background: #1962a3;
  color: #ffffff;
  text-shadow: none; }

::selection {
  background: #1962a3;
  color: #ffffff;
  text-shadow: none; }

.fw-medium {
  font-weight: 500; }

.fw-600 {
  font-weight: 600; }

.border-radius {
  border-radius: 6px; }

.border-radius-sm {
  border-radius: 4px; }

.border-radius-md {
  border-radius: 10px; }

.border-radius-lg {
  border-radius: 50%; }

.box-shadow {
  box-shadow: 0px 1px 30px 0px rgba(9, 28, 58, 0.09); }

.box-shadow-sm {
  box-shadow: 0px 2px 5px 0px rgba(9, 28, 58, 0.1); }

.box-shadow-lg {
  box-shadow: 0 1rem 3rem rgba(9, 28, 58, 0.175); }

/* form-control */
.form-control, .form-select {
  border: 1px solid #ededed;
  border-radius: 0px;
  height: 60px;
  padding: 10px 20px;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  color: #091c3a;
  background-clip: inherit !important; }
  .form-control:focus, .form-select:focus {
    box-shadow: none;
    border-color: #1962a3; }

.avatar-lg {
  min-width: 80px;
  width: 80px; }

/* input-group-prepend */
.input-group-prepend .input-group-text, .input-group-append .input-group-text {
  width: 50px;
  background: transparent;
  border-color: #ededed;
  box-shadow: none;
  justify-content: center; }

/* file-upload */
.input-group.file-upload {
  height: 50px; }
  .input-group.file-upload .form-control {
    height: 50px;
    color: #8d8c8c;
    border-radius: 3px;
    border-color: #ededed;
    position: relative;
    z-index: 2;
    width: 100%;
    margin: 0;
    opacity: 0;
    border-radius: 3px; }
  .input-group.file-upload .input-group-text {
    height: 50px;
    font-size: 14px;
    font-weight: 600;
    color: #8d8c8c;
    border-color: #ededed;
    padding: 15px 90px 15px 20px;
    border-radius: 3px;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    line-height: 1.5;
    background-color: #ffffff;
    justify-content: space-between;
    margin-left: 0; }
    .input-group.file-upload .input-group-text:after {
      content: "Browse";
      height: 48px;
      padding: 14px 25px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      display: block;
      line-height: 1.5;
      color: #091c3a;
      background-color: #f0f6fa;
      border-left: inherit;
      border-radius: 0px 3px 3px 0px; }

@media (max-width: 1400px) {
  h1 {
    font-size: 90px; } }

@media (max-width: 1199px) {
  h1 {
    font-size: 80px; } }

@media (max-width: 991px) {
  h1 {
    font-size: 65px;
    line-height: 1; } }

@media (max-width: 767px) {
  h1 {
    font-size: 60px; }
  h2 {
    font-size: 30px; }
  h3 {
    font-size: 26px; }
  h4 {
    font-size: 24px; } }

@media (max-width: 575px) {
  h1 {
    font-size: 30px; }
  h2 {
    font-size: 28px; } }

/*****************************
  Button
*****************************/
.btn {
  border: none;
  z-index: 1;
  position: relative;
  font-size: 16px;
  padding: 15px 40px;
  overflow: hidden;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  text-transform: capitalize; }
  .btn:focus {
    box-shadow: none;
    outline: none; }
  .btn:active {
    box-shadow: none;
    outline: none; }

.btn.btn-link {
  padding: 0 !important; }

/*Btn Color*/
.btn.btn-primary {
  background: #1962a3;
  color: #ffffff; }
  .btn.btn-primary:hover {
    background: #091c3a;
    transform: translateY(-2px); }
  .btn.btn-primary:active {
    box-shadow: none; }
  .btn.btn-primary:focus {
    box-shadow: none; }

.btn-white {
  background: #ffffff;
  color: #000000; }
  .btn-white:hover {
    background: #1962a3;
    color: #ffffff;
    transform: translateY(-2px); }
  .btn-white:focus {
    color: #000000; }

.btn-secondary {
  background: #091c3a;
  color: #ffffff; }
  .btn-secondary:hover {
    background: #1962a3;
    transform: translateY(-2px); }

.bg-dark .btn-primary:hover {
  background: #ffffff;
  color: #1962a3; }

.btn.text-secondary:hover {
  color: #1962a3 !important; }

.btn.text-white:hover {
  color: #1962a3 !important; }

.btn.text-primary:hover {
  color: #091c3a !important; }

/*Btn Size*/
.btn.btn-lg {
  font-size: 18px;
  padding: 20px 40px; }

.btn.btn-md {
  font-size: 14px;
  padding: 15px 30px; }

.btn.btn-sm {
  font-size: 14px;
  padding: 12px 20px; }

/*btn-app*/
.btn-app {
  font-size: 14px;
  padding: 8px 20px;
  background-color: #091c3a;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  text-transform: capitalize; }
  .btn-app i {
    font-size: 30px;
    padding-right: 10px; }
  .btn-app .small-text {
    font-size: 11px; }
  .btn-app span {
    line-height: 14px; }
  .btn-app:hover {
    background-color: #1962a3;
    color: #ffffff;
    transform: translateY(-2px); }

@media (max-width: 575px) {
  .btn {
    font-size: 14px;
    padding: 12px 20px; }
  .btn-app {
    display: inline-flex;
    padding: 8px 14px; }
    .btn-app i {
      font-size: 24px;
      padding-right: 6px; } }

/*****************************
  Feature Info
*****************************/
.feature-item .feature-icon {
  padding-bottom: 15px;
  display: flex; }

.feature-item .feature-content .feature-title {
  padding-bottom: 15px;
  margin-bottom: 0;
  font-weight: 600; }

.feature-item .feature-content p {
  margin-bottom: 0; }

/*feature style 01*/
.feature-item.feature-info-style-01 {
  padding: 40px 40px 60px;
  transition: all 0.3s ease-in-out;
  background-color: #f0f6fa; }
  .feature-item.feature-info-style-01 .feature-icon {
    padding-bottom: 0; }
    .feature-item.feature-info-style-01 .feature-icon object {
      width: 120px;
      filter: invert(29%) sepia(100%) saturate(1887%) hue-rotate(211deg) brightness(102%) contrast(94%);
      transition: all 0.3s ease-in-out; }
  .feature-item.feature-info-style-01:hover {
    background-color: #1962a3;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1); }
    .feature-item.feature-info-style-01:hover .feature-icon object {
      filter: invert(99%) sepia(67%) saturate(351%) hue-rotate(195deg) brightness(117%) contrast(101%);
      webkit-filter: invert(99%) sepia(67%) saturate(351%) hue-rotate(195deg) brightness(117%) contrast(101%);
      -webkit-filter: invert(99%) sepia(67%) saturate(351%) hue-rotate(195deg) brightness(117%) contrast(101%);
      -moz-filter: invert(99%) sepia(67%) saturate(351%) hue-rotate(195deg) brightness(117%) contrast(101%); }
    .feature-item.feature-info-style-01:hover .feature-content h4 {
      color: #ffffff; }
    .feature-item.feature-info-style-01:hover .feature-content p {
      color: #ffffff; }

.feature-item.feature-info-style-01.active {
  background-color: #1962a3;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1); }
  .feature-item.feature-info-style-01.active .feature-icon object {
    filter: invert(99%) sepia(67%) saturate(351%) hue-rotate(195deg) brightness(117%) contrast(101%); }
  .feature-item.feature-info-style-01.active .feature-content h4 {
    color: #ffffff; }
  .feature-item.feature-info-style-01.active .feature-content p {
    color: #ffffff; }

.feature-info-center {
  text-align: center; }
  .feature-info-center .feature-icon {
    justify-content: center; }

.feature-info-right {
  text-align: right; }
  .feature-info-right .feature-icon {
    justify-content: end; }

.feature-item.feature-info-style-01.feature-info-center .feature-icon {
  display: inherit;
  justify-content: inherit; }

/*feature style 02*/
.feature-item.feature-info-style-02 {
  padding: 23px 40px;
  border: 1px solid #ededed; }
  .feature-item.feature-info-style-02 .feature-icon object {
    width: 70px;
    filter: invert(29%) sepia(100%) saturate(1887%) hue-rotate(211deg) brightness(102%) contrast(94%);
    transition: all 0.3s ease-in-out; }

.feature-item.feature-info-style-02.feature-info-center .feature-icon {
  display: inherit;
  justify-content: inherit; }

/*feature style 03*/
.feature-item.feature-info-style-03 {
  margin-bottom: 30px;
  display: flex; }
  .feature-item.feature-info-style-03 .feature-icon {
    padding-bottom: 0;
    height: 100px;
    min-width: 100px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 30px;
    background-color: #1962a3; }
    .feature-item.feature-info-style-03 .feature-icon object {
      width: 40px;
      filter: invert(99%) sepia(67%) saturate(351%) hue-rotate(195deg) brightness(117%) contrast(101%);
      transition: all 0.3s ease-in-out; }
  .feature-item.feature-info-style-03 .feature-content h6.feature-title {
    color: #1962a3;
    padding-bottom: 7px; }
  .feature-item.feature-info-style-03 .feature-content span {
    display: block; }

.workplaces-service-box .feature-info-style-01 {
  background-color: transparent; }

/*feature style 04*/
.feature-item.feature-info-style-04 {
  display: flex; }
  .feature-item.feature-info-style-04 .feature-icon {
    height: 100px;
    width: 100px;
    flex: 0 0 100px;
    line-height: 100px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 30px;
    padding-bottom: 0;
    background-color: #1962a3; }
    .feature-item.feature-info-style-04 .feature-icon object {
      width: 110px;
      filter: invert(99%) sepia(67%) saturate(351%) hue-rotate(195deg) brightness(117%) contrast(101%);
      transition: all 0.3s ease-in-out; }
  .feature-item.feature-info-style-04 .feature-content h4 {
    color: #ffffff; }
  .feature-item.feature-info-style-04 .feature-content p {
    color: #ffffff; }

.feature-item.feature-info-style-04.feature-item-right .feature-icon {
  margin-left: 30px; }

@media (max-width: 1200px) {
  .feature-item.feature-info-style-01 {
    margin-bottom: 30px; } }

@media (max-width: 991px) {
  .feature-item.feature-info-style-02 {
    margin-bottom: 30px; }
  .electric-feature-section .feature-items.feature-info-style-04 {
    margin-top: 0;
    margin-bottom: 30px; }
  .feature-item.feature-info-style-04.feature-item-right .feature-icon {
    margin-left: 0px; } }

@media (max-width: 479px) {
  .feature-item.feature-info-style-04 {
    margin-bottom: 30px;
    display: block;
    text-align: center; }
    .feature-item.feature-info-style-04 .feature-icon {
      margin: 0 auto;
      height: 70px;
      width: 70px; }
      .feature-item.feature-info-style-04 .feature-icon object {
        width: 70px; }
    .feature-item.feature-info-style-04 .feature-content {
      padding-top: 20px; }
  .feature-item.feature-info-style-04.feature-item-right .feature-icon {
    margin: 0 auto; } }

/*****************************
 Service Info
*****************************/
.service-item .service-img {
  overflow: hidden;
  position: relative;
  transition: transform 0.8s cubic-bezier(0.05, 0.2, 0.1, 1); }
  .service-item .service-img .service-img-inner {
    transition: transform 0.8s cubic-bezier(0.05, 0.2, 0.1, 1); }

.service-item .service-content .service-title {
  font-weight: 600;
  margin-bottom: 0; }

.service-item:hover .service-img {
  transform: scale(0.95); }
  .service-item:hover .service-img .service-img-inner {
    transform: scale(1.15); }

/*service style 01*/
.service-box-section {
  background-image: url(../images/service/bg-01.png);
  background-repeat: no-repeat;
  background-position: bottom right; }

.service-item.service-info-style-01 {
  display: flex;
  margin-bottom: 80px;
  align-items: center; }
  .service-item.service-info-style-01 .service-img {
    margin-right: 30px;
    width: 200px;
    min-width: 200px; }
  .service-item.service-info-style-01 .service-content h5 {
    font-weight: 600;
    display: inline-block; }
  .service-item.service-info-style-01 .service-content p {
    margin: 15px 0 20px 0; }
  .service-item.service-info-style-01 .service-content a {
    color: #091c3a;
    font-weight: 600; }
    .service-item.service-info-style-01 .service-content a i {
      color: #1962a3; }
    .service-item.service-info-style-01 .service-content a:hover {
      color: #1962a3; }

/*service style 02*/
.service-item.service-info-style-02 .service-content {
  padding-top: 40px; }
  .service-item.service-info-style-02 .service-content h3 {
    padding-bottom: 20px;
    margin-bottom: 0; }
  .service-item.service-info-style-02 .service-content p {
    margin-bottom: 0; }

.service-box-section .service-item.service-info-style-02 {
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: column; }
  .service-box-section .service-item.service-info-style-02 .service-content {
    padding: 142px 50px; }

/*service-detail*/
.service-detail .service-content h2 {
  font-size: 36px; }

.service-detail .service-detail-content {
  padding-left: 100px; }
  .service-detail .service-detail-content .service-info-detail h5 {
    color: #1962a3; }

@media (max-width: 1400px) {
  .service-box-section .service-item.service-info-style-02 .service-content {
    padding: 72px 50px; } }

@media (max-width: 1199px) {
  .service-detail .service-detail-content {
    padding-left: 20px; }
  .service-box-section .service-item.service-info-style-02 .service-content {
    padding: 40px 30px; } }

@media (max-width: 991px) {
  .service-item.service-info-style-01 {
    margin-bottom: 30px; }
    .service-item.service-info-style-01 .service-img {
      margin-right: 30px; }
  .service-item.service-info-style-02 {
    margin-bottom: 30px; }
    .service-item.service-info-style-02 .service-content {
      padding-top: 20px; }
      .service-item.service-info-style-02 .service-content h3.service-title {
        padding-bottom: 15px; }
  /*Home 03*/
  .service-box-section .section-title {
    margin-bottom: 10px; }
  .service-box-section .service-item.service-info-style-02 .service-content {
    padding: 30px; }
  .service-box-section .overlay-service-box {
    margin-top: 0; }
  .service-detail .service-detail-content {
    padding-left: 0px;
    margin-top: 30px; } }

@media (max-width: 767px) {
  .service-item.service-info-style-02 {
    margin-bottom: 30px; }
  /*service-detail*/
  .service-detail .service-detail-content {
    padding-left: 0px; } }

@media (max-width: 575px) {
  .service-item.service-info-style-01 {
    display: block;
    margin-bottom: 30px; }
    .service-item.service-info-style-01 .service-img {
      width: 100%;
      margin-bottom: 20px; }
    .service-item.service-info-style-01 .service-content p {
      margin: 15px 0 20px 0; } }

/*****************************
 testimonial
*****************************/
.testimonial-light-bg .testimonial-description {
  padding-left: 90px;
  padding-right: 100px; }
  .testimonial-light-bg .testimonial-description .section-title {
    margin-bottom: 30px; }
  .testimonial-light-bg .testimonial-description .owl-nav {
    padding-top: 45px; }
    .testimonial-light-bg .testimonial-description .owl-nav .owl-prev {
      margin-right: 15px; }
      .testimonial-light-bg .testimonial-description .owl-nav .owl-prev i {
        font-size: 24px;
        color: #091c3a; }
        .testimonial-light-bg .testimonial-description .owl-nav .owl-prev i:hover {
          color: #1962a3; }
    .testimonial-light-bg .testimonial-description .owl-nav .owl-next i {
      font-size: 24px;
      color: #091c3a; }
      .testimonial-light-bg .testimonial-description .owl-nav .owl-next i:hover {
        color: #1962a3; }

/*Testimonial style 01*/
.testimonial {
  position: relative;
  padding-top: 50px; }
  .testimonial .testimonial-star-icon ul {
    display: flex;
    padding-left: 0;
    margin-bottom: 20px; }
    .testimonial .testimonial-star-icon ul li {
      list-style-type: none;
      margin-right: 5px;
      font-size: 16px; }
  .testimonial .testimonial-quote-icon {
    width: 105px;
    position: absolute;
    left: 25px;
    top: 5px;
    opacity: 1;
    z-index: -1; }
  .testimonial .testimonial-author {
    margin-top: 30px;
    display: inline-block; }
    .testimonial .testimonial-author .avatar img {
      width: 55px;
      border-radius: 5px; }
    .testimonial .testimonial-author .testimonial-name {
      padding-top: 25px;
      display: flex; }
      .testimonial .testimonial-author .testimonial-name .author-tittle {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 0; }
      .testimonial .testimonial-author .testimonial-name span {
        position: relative;
        padding-left: 15px;
        margin-left: 15px;
        font-size: 14px; }
        .testimonial .testimonial-author .testimonial-name span:before {
          content: "";
          height: 1px;
          width: 5px;
          background: #8d8c8c;
          position: absolute;
          left: 0;
          top: 50%; }
  .testimonial .testimonial-content {
    margin-bottom: 20px; }
    .testimonial .testimonial-content p {
      font-size: 22px;
      line-height: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical; }

/*Testimonial style 01*/
.testimonial-dark-bg .testimonial-description {
  padding: 70px 90px 90px 90px;
  position: relative;
  overflow: hidden;
  margin-bottom: -100px;
  background-color: #1962a3; }
  .testimonial-dark-bg .testimonial-description .testimonial .testimonial-content p {
    color: #ffffff;
    font-size: 22px;
    line-height: 38px; }
  .testimonial-dark-bg .testimonial-description .testimonial .testimonial-author .testimonial-name h6 {
    color: #ffffff;
    font-weight: 600; }
  .testimonial-dark-bg .testimonial-description .testimonial .testimonial-author .testimonial-name span {
    color: #1962a3; }
    .testimonial-dark-bg .testimonial-description .testimonial .testimonial-author .testimonial-name span:before {
      background: #ffffff; }
  .testimonial-dark-bg .testimonial-description .testimonial .testimonial-quote-icon {
    opacity: 0.2; }
  .testimonial-dark-bg .testimonial-description .owl-nav button i {
    color: #ffffff; }
  .testimonial-dark-bg .testimonial-description:before {
    content: "";
    background: url(../images/testimonial/quote.svg);
    position: absolute;
    top: 100px;
    left: inherit;
    right: -50px;
    width: 450px;
    height: 100%;
    background-position: right;
    background-repeat: no-repeat;
    opacity: 0.04;
    z-index: 9; }

.testimonial-dark-bg .owl-nav {
  padding-top: 45px; }
  .testimonial-dark-bg .owl-nav .owl-prev {
    margin-right: 15px; }
    .testimonial-dark-bg .owl-nav .owl-prev i {
      font-size: 24px;
      color: #091c3a; }
  .testimonial-dark-bg .owl-nav .owl-next i {
    font-size: 24px;
    color: #091c3a; }

/*Testimonial style 02*/
.testimonial-style-bg .testimonial-description {
  padding: 190px 150px 190px 100px; }

.testimonial-style-bg .owl-carousel .owl-nav {
  position: absolute;
  left: inherit;
  right: 0;
  bottom: 10px; }
  .testimonial-style-bg .owl-carousel .owl-nav .owl-prev {
    margin-right: 15px; }
    .testimonial-style-bg .owl-carousel .owl-nav .owl-prev i {
      font-size: 24px;
      color: #ffffff; }
  .testimonial-style-bg .owl-carousel .owl-nav .owl-next i {
    font-size: 24px;
    color: #ffffff; }

/*Testimonial style 02*/
.testimonial-style-02 {
  position: relative; }
  .testimonial-style-02 .testimonial-author {
    display: inline-block;
    padding-top: 30px; }
    .testimonial-style-02 .testimonial-author .testimonial-name .author-tittle {
      font-size: 26px;
      font-weight: bold;
      color: #1962a3; }
    .testimonial-style-02 .testimonial-author .testimonial-name span {
      position: relative;
      padding-left: 15px;
      font-size: 18px;
      color: #ffffff; }
      .testimonial-style-02 .testimonial-author .testimonial-name span:before {
        content: "";
        height: 1px;
        width: 5px;
        background: #ffffff;
        position: absolute;
        left: 0;
        top: 50%; }
  .testimonial-style-02 .testimonial-star-icon {
    padding-bottom: 10px; }
    .testimonial-style-02 .testimonial-star-icon ul {
      display: flex; }
      .testimonial-style-02 .testimonial-star-icon ul li {
        padding-right: 10px; }
        .testimonial-style-02 .testimonial-star-icon ul li i {
          color: #f5bc37;
          font-size: 32px; }
        .testimonial-style-02 .testimonial-star-icon ul li i.far {
          color: #ffffff; }
  .testimonial-style-02 .testimonial-content p {
    font-size: 30px;
    line-height: 40px;
    color: #ffffff; }

/*testimonial content center*/
.testimonial-content-center.testimonial {
  text-align: center; }
  .testimonial-content-center.testimonial .testimonial-quote-icon {
    top: inherit;
    left: 50%;
    transform: translate(-50%, -50%); }
  .testimonial-content-center.testimonial .testimonial-author {
    justify-content: center; }
    .testimonial-content-center.testimonial .testimonial-author .testimonial-avatar img {
      margin: 0 auto; }
    .testimonial-content-center.testimonial .testimonial-author .testimonial-name {
      padding-top: 20;
      display: flex; }

.testimonial .testimonial-author.info-right {
  display: flex;
  align-items: center; }
  .testimonial .testimonial-author.info-right .testimonial-name {
    display: flex;
    padding-top: 0;
    padding-left: 15px; }

@media (max-width: 1199px) {
  .testimonial-light-bg .testimonial-description {
    padding-left: 50px;
    padding-right: 50px; }
  .testimonial-style-02 .testimonial-description {
    padding: 100px 50px 100px 50px; } }

@media (max-width: 991px) {
  .testimonial-light-bg .testimonial-bg {
    position: inherit !important;
    height: 500px !important;
    display: block !important; }
  .testimonial-style-02 .testimonial-description {
    padding: 80px 50px 80px 50px; }
    .testimonial-style-02 .testimonial-description .owl-carousel .testimonial .testimonial-author {
      padding-top: 70px; }
  .testimonial-style-bg .testimonial-description {
    padding: 80px 80px 80px 70px; }
    .testimonial-style-bg .testimonial-description .owl-carousel .testimonial-style-02 .testimonial-content p {
      font-size: 28px;
      line-height: 38px; } }

@media (max-width: 767px) {
  .testimonial .testimonial-content p {
    font-size: 22px;
    line-height: 32px; }
  .testimonial .testimonial-author {
    margin-top: 25px; }
  .testimonial-dark-bg .testimonial-description {
    padding-left: 15px;
    padding-right: 15px; }
    .testimonial-dark-bg .testimonial-description .owl-carousel .testimonial {
      padding-top: 30px; }
      .testimonial-dark-bg .testimonial-description .owl-carousel .testimonial .testimonial-quote-icon {
        width: 80px; }
  .testimonial-dark-bg .testimonial-description {
    padding: 50px; }
    .testimonial-dark-bg .testimonial-description:before {
      width: 300px; }
  .testimonial-style-bg .testimonial-description {
    padding: 50px 20px 50px 20px; }
    .testimonial-style-bg .testimonial-description .owl-carousel .testimonial-style-02 .testimonial-star-icon {
      padding-bottom: 15px; }
      .testimonial-style-bg .testimonial-description .owl-carousel .testimonial-style-02 .testimonial-star-icon ul li i {
        font-size: 24px; }
    .testimonial-style-bg .testimonial-description .owl-carousel .testimonial-style-02 .testimonial-content p {
      font-size: 22px;
      line-height: 32px; }
    .testimonial-style-bg .testimonial-description .owl-carousel .testimonial-style-02 .testimonial-author {
      padding-top: 50px; }
      .testimonial-style-bg .testimonial-description .owl-carousel .testimonial-style-02 .testimonial-author .testimonial-name .author-tittle {
        font-size: 22px; } }

@media (max-width: 575px) {
  .testimonial-dark-bg .testimonial-description {
    padding: 30px; }
    .testimonial-dark-bg .testimonial-description:before {
      width: 200px; }
  .testimonial-style-bg .testimonial-description .owl-carousel .owl-nav {
    position: inherit;
    left: inherit;
    right: 0;
    top: 0;
    padding-top: 30px;
    text-align: center; }
  .testimonial-light-bg .testimonial-description {
    padding-left: 0px;
    padding-right: 0px; } }

@media (max-width: 479px) {
  /*testimonial content center*/
  .testimonial-content-center.testimonial .testimonial-author {
    display: block; }
    .testimonial-content-center.testimonial .testimonial-author .testimonial-name {
      padding-left: 0px;
      padding-top: 15px;
      justify-content: center; } }

/*****************************
 accordion
*****************************/
.accordion .accordion-item {
  border: 0;
  background-color: inherit; }
  .accordion .accordion-item h3.accordion-header {
    border-bottom: 1px solid #cccccc;
    padding-bottom: 30px;
    margin-bottom: 30px; }
    .accordion .accordion-item h3.accordion-header .accordion-button {
      font-size: inherit;
      padding: 0;
      font-weight: 600;
      background-color: inherit;
      color: #091c3a; }
      .accordion .accordion-item h3.accordion-header .accordion-button:after {
        content: "\2b";
        background-image: inherit;
        font-family: 'Font Awesome 5 Free';
        font-size: 18px;
        margin-right: 20px;
        color: #091c3a; }
      .accordion .accordion-item h3.accordion-header .accordion-button:hover {
        color: #1962a3; }
    .accordion .accordion-item h3.accordion-header .accordion-button:hover:after {
      color: #1962a3; }
    .accordion .accordion-item h3.accordion-header .accordion-button:not(.collapsed) {
      background-color: inherit;
      box-shadow: inherit;
      outline: inherit;
      color: #1962a3; }
      .accordion .accordion-item h3.accordion-header .accordion-button:not(.collapsed):after {
        content: "\f068";
        background-image: inherit;
        font-family: 'Font Awesome 5 Free';
        transform: inherit;
        color: #1962a3; }
  .accordion .accordion-item .accordion-button:focus {
    border-color: inherit;
    box-shadow: inherit; }
  .accordion .accordion-item .accordion-collapse .accordion-body {
    padding: 0px 15px 30px 15px;
    color: #8d8c8c; }
  .accordion .accordion-item:last-child .accordion-header {
    margin-bottom: 0; }
  .accordion .accordion-item:last-child .accordion-body {
    padding-top: 30px;
    padding-bottom: 0px; }

.checkout-review .accordion .card {
  border: none;
  padding: 8px 0px;
  margin-bottom: 5px; }
  .checkout-review .accordion .card .accordion-icon.card-header {
    background: none;
    padding: 0;
    border-bottom: none; }
    .checkout-review .accordion .card .accordion-icon.card-header button {
      padding: 0px 0px 0px 30px;
      font-size: 18px;
      font-weight: 600; }
      .checkout-review .accordion .card .accordion-icon.card-header button:before {
        font-size: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        content: "\f068";
        font-family: "Font Awesome 5 Free";
        border: 2px solid #1962a3;
        width: 18px;
        height: 18px;
        line-height: 14px;
        border-radius: 50%;
        z-index: 9;
        text-align: center;
        font-weight: 600; }
    .checkout-review .accordion .card .accordion-icon.card-header button.collapsed:before {
      content: "\f067"; }
  .checkout-review .accordion .card .card-body {
    padding: 10px 0px 0px 30px; }

.map-section .accordion {
  margin-left: 50px; }

@media (max-width: 991px) {
  .map-section .accordion {
    margin-left: 0px; } }

@media (max-width: 767px) {
  .accordion .accordion-item h3.accordion-header {
    padding-bottom: 15px;
    margin-bottom: 20px;
    font-size: 20px; }
    .accordion .accordion-item h3.accordion-header .accordion-button:after {
      margin-right: 0px; } }

/*****************************
Counter
*****************************/
.counter-section {
  padding: 40px 0;
  background-color: #1962a3; }

.counter .counter-number {
  text-align: center; }
  .counter .counter-number h2.timer {
    font-size: 60px;
    line-height: 70px;
    font-weight: 600;
    color: #ffffff; }
  .counter .counter-number p {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500; }

.counter .counter-number .counter-text {
  display: flex;
  align-items: center;
  justify-content: center; }
  .counter .counter-number .counter-text span {
    font-size: 60px;
    line-height: 70px;
    color: #1962a3;
    font-weight: 600; }

.counter-section.bg-primary .counter .counter-number .counter-text span {
  color: #ffffff; }

.service-section .counter {
  border-right: 1px solid rgba(255, 255, 255, 0.1); }

@media (max-width: 991px) {
  .counter {
    border-right: inherit !important;
    margin-bottom: 30px; }
    .counter .counter-number h2.timer {
      font-size: 60px;
      line-height: 70px; }
  .video-counter {
    padding: 40px 0; }
    .video-counter .video-box {
      margin-bottom: 30px; } }

@media (max-width: 767px) {
  .counter .counter-number h2.timer {
    font-size: 50px;
    line-height: 60px; } }

@media (max-width: 479px) {
  .counter-area .counter-02 {
    width: 100%; } }

/*****************************
 Newsletter
*****************************/
.newsletter form .form-group .form-control {
  border: 1px solid transparent;
  border-bottom: 1px solid #ffffff;
  font-size: 18px;
  color: #ffffff;
  font-weight: normal;
  background-color: inherit;
  padding: 10px 130px 10px 20px; }

.newsletter form .form-group input::placeholder {
  color: #ffffff; }

.newsletter form .btn.btn-link {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: #ffffff;
  text-transform: capitalize;
  text-decoration: none; }
  .newsletter form .btn.btn-link:hover {
    color: #1962a3; }

@media (max-width: 479px) {
  .newsletter form .btn {
    position: inherit;
    padding: 20px 0; } }

/*****************************
 Product List
*****************************/
.product-list .product-info {
  display: flex;
  align-items: center;
  padding: 20px 25px 20px 20px; }
  .product-list .product-info .product-img {
    flex: 0 0 200px;
    margin-right: 30px; }
  .product-list .product-info .product-content {
    flex: 0 0 510px; }
    .product-list .product-info .product-content h6 {
      font-weight: 600;
      margin-bottom: 0; }
    .product-list .product-info .product-content p {
      padding: 10px 0 20px 0;
      margin-bottom: 0; }
    .product-list .product-info .product-content ul {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0; }
      .product-list .product-info .product-content ul li a {
        display: flex;
        color: #091c3a;
        text-decoration: underline !important; }
        .product-list .product-info .product-content ul li a object {
          filter: invert(29%) sepia(100%) saturate(1887%) hue-rotate(211deg) brightness(102%) contrast(94%); }
        .product-list .product-info .product-content ul li a:hover {
          color: #1962a3; }
  .product-list .product-info .pricing-price {
    margin-left: auto; }
    .product-list .product-info .pricing-price span {
      padding-right: 20px;
      display: flex;
      align-items: center;
      color: #091c3a; }
      .product-list .product-info .pricing-price span sup {
        top: 0;
        font-weight: 600;
        font-size: 30px; }
      .product-list .product-info .pricing-price span strong {
        font-size: 30px;
        font-weight: 600; }

@media (max-width: 1400px) and (min-width: 1200px) {
  .product-list .product-info .product-img {
    margin-right: 30px; }
  .product-list .product-info .product-content {
    flex: 0 0 360px; }
  .product-list .product-info .pricing-price {
    flex: inherit; } }

@media (max-width: 1200px) {
  .product-list .product-info {
    padding: 25px;
    display: block; }
    .product-list .product-info .product-img {
      margin-right: 20px;
      padding-bottom: 20px; }
    .product-list .product-info .product-content {
      flex: 0 0 350px;
      margin-bottom: 20px; }
    .product-list .product-info .pricing-price {
      margin-bottom: 10px; }
      .product-list .product-info .pricing-price span {
        padding-right: 10px; } }

@media (max-width: 991px) {
  .product-list .product-info {
    display: block; }
    .product-list .product-info .product-img {
      width: 100%;
      padding-bottom: 20px; }
    .product-list .product-info .product-content {
      width: 100%; } }

@media (max-width: 767px) {
  .product-list .product-info {
    padding: 20px; }
    .product-list .product-info .product-content p {
      padding: 10px 0; }
    .product-list .product-info .product-content ul li {
      margin-bottom: 10px; }
      .product-list .product-info .product-content ul li:last-child {
        margin-bottom: 0; } }

@media (max-width: 479px) {
  .product-list .product-info {
    padding: 20px; }
    .product-list .product-info .pricing-price {
      display: block;
      margin: 10px 0; }
      .product-list .product-info .pricing-price .btn {
        margin-top: 10px; } }

/*****************************
	Portfolio
*****************************/
.portfolio-item {
  position: relative; }
  .portfolio-item .portfolio-img {
    position: absolute;
    right: 30px;
    bottom: 70px;
    opacity: 0;
    transition: all 0.3s ease; }
    .portfolio-item .portfolio-img i {
      font-size: 20px;
      color: #ffffff;
      display: inline-flex;
      width: 54px;
      height: 54px;
      line-height: 30px;
      background-color: #1962a3;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      transition: all 0.5s ease; }
  .portfolio-item .portfolio-info {
    margin-top: 15px;
    margin-bottom: 35px;
    position: relative; }
    .portfolio-item .portfolio-info .category-title {
      display: inline-block; }
    .portfolio-item .portfolio-info .title {
      font-weight: 600;
      line-height: 34px; }
  .portfolio-item:hover .portfolio-img {
    bottom: 100px;
    opacity: 1; }

.portfolio-slider {
  margin-right: -500px; }
  .portfolio-slider .owl-carousel .portfolio-item .portfolio-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 30px;
    transition: all 0.3s ease-in-out; }
    .portfolio-slider .owl-carousel .portfolio-item .portfolio-info .portfolio-tag a {
      display: block; }
    .portfolio-slider .owl-carousel .portfolio-item .portfolio-info .portfolio-tag .category-title {
      color: #1962a3;
      font-size: 16px;
      font-weight: 500; }
      .portfolio-slider .owl-carousel .portfolio-item .portfolio-info .portfolio-tag .category-title:hover {
        color: #ffffff; }
    .portfolio-slider .owl-carousel .portfolio-item .portfolio-info .portfolio-tag .title {
      font-size: 32px;
      color: #ffffff;
      font-weight: bold; }
      .portfolio-slider .owl-carousel .portfolio-item .portfolio-info .portfolio-tag .title:hover {
        color: #1962a3; }
  .portfolio-slider .owl-carousel .portfolio-item:hover .portfolio-img {
    bottom: 0px;
    opacity: 1; }
  .portfolio-slider .owl-carousel .owl-nav {
    position: absolute;
    bottom: 0;
    left: -50px; }
    .portfolio-slider .owl-carousel .owl-nav button {
      background: rgba(9, 28, 58, 0.12);
      padding: 15px;
      display: inline-flex;
      height: 50px;
      width: 50px;
      justify-content: center;
      color: #ffffff;
      align-items: center;
      font-size: 22px;
      transition: all 0.3s ease; }
      .portfolio-slider .owl-carousel .owl-nav button:hover {
        background: #1962a3; }
  .portfolio-slider .owl-carousel .portfolio-img {
    bottom: -50px; }
    .portfolio-slider .owl-carousel .portfolio-img i {
      font-size: 20px;
      color: #ffffff;
      display: inline-flex;
      width: 54px;
      height: 54px;
      line-height: 30px;
      background-color: #1962a3;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      transition: all 0.5s ease; }
      .portfolio-slider .owl-carousel .portfolio-img i:hover {
        background-color: #091c3a; }

.my-shuffle-container {
  margin-left: -12px;
  margin-right: -12px; }
  .my-shuffle-container .grid-item {
    width: 33.33%;
    padding: 15px; }

/*portfolio Detail*/
.portfolio-content {
  margin-top: -50px;
  padding: 40px 25px 0;
  background-color: #ffffff; }
  .portfolio-content .social > p {
    color: #091c3a;
    font-size: 16px;
    font-family: "Outfit", sans-serif; }
  .portfolio-content .social a {
    color: #8d8c8c; }
    .portfolio-content .social a:hover {
      color: #1962a3; }

.portfolio-slider.portfolio-slider-detail .owl-carousel .portfolio-item:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(9, 28, 58, 0.3); }

.portfolio-slider.portfolio-slider-detail .owl-carousel .portfolio-item .portfolio-info {
  margin-bottom: 0px; }

.my-shuffle-container .portfolio-item .portfolio-info {
  margin-bottom: 0px; }

.filters-group {
  display: table;
  margin: 0 auto 50px;
  text-align: center; }
  .filters-group button {
    margin: 0 1px 4px;
    padding: 12px 25px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    color: #091c3a;
    border: none;
    background: inherit;
    transition: all 0.3s ease; }
    .filters-group button span {
      display: block; }
    .filters-group button:hover {
      background: #1962a3;
      color: #ffffff; }
  .filters-group button.active {
    background: #1962a3;
    color: #ffffff; }

.bg-dark .portfolio-item .portfolio-info .category-title {
  color: #ffffff; }
  .bg-dark .portfolio-item .portfolio-info .category-title:hover {
    color: #1962a3; }

.portfolio-post-navigation .portfolio-navigation .nav-link {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .portfolio-post-navigation .portfolio-navigation .nav-link .nav-previous a {
    display: flex;
    align-items: center;
    justify-content: start; }
    .portfolio-post-navigation .portfolio-navigation .nav-link .nav-previous a .portfolio-img {
      flex: 0 0 100px;
      margin: 0 20px; }
  .portfolio-post-navigation .portfolio-navigation .nav-link .grid-icon i {
    font-size: 34px; }
  .portfolio-post-navigation .portfolio-navigation .nav-link .nav-next a {
    display: flex;
    align-items: center;
    justify-content: end; }
    .portfolio-post-navigation .portfolio-navigation .nav-link .nav-next a .portfolio-img {
      flex: 0 0 100px;
      margin: 0 20px; }

/*************************************
         shuffle
 *************************************/
.my-shuffle-container.grid-style .grid-item {
  padding: 0 !important; }

.my-shuffle-container.columns-1 .grid-item {
  width: 100%;
  padding: 15px; }

.my-shuffle-container.columns-2 .grid-item {
  width: 50%;
  padding: 15px;
  float: left; }

.my-shuffle-container.columns-3 .grid-item {
  width: 33.33333333%;
  padding: 15px; }

.my-shuffle-container.columns-4 .grid-item {
  width: 25%;
  padding: 10px; }

.my-shuffle-container.columns-5 .grid-item {
  width: 20%;
  padding: 15px; }

.my-shuffle-container.columns-6 .grid-item {
  width: 16.666666%;
  padding: 15px; }

.masonry.my-shuffle-container .grid-item {
  padding: 15px;
  float: left; }

.masonry.my-shuffle-container .m-col-3 {
  width: 25%; }

.masonry.my-shuffle-container .m-col-4 {
  width: 40%; }

.masonry.my-shuffle-container .m-col-5 {
  width: 50%; }

.masonry.my-shuffle-container .m-col-6 {
  width: 50%; }

.masonry.my-shuffle-container .m-col-7 {
  width: 65%; }

.portfolio .portfolio-slider.portfolio-slider-detail {
  margin-right: 0;
  margin-left: 0; }

@media (max-width: 1200px) {
  .my-shuffle-container.columns-6 .grid-item {
    width: 25.00%;
    padding: 15px; }
  .portfolio .portfolio-slider {
    margin-right: 0;
    margin-left: 50px; } }

@media (max-width: 991px) {
  .my-shuffle-container.columns-6 .grid-item {
    width: 33.333%;
    padding: 15px; }
  .my-shuffle-container.columns-5 .grid-item {
    width: 33.333%;
    padding: 15px; } }

@media (max-width: 767px) {
  .my-shuffle-container .grid-item, .my-shuffle-container.columns-3 .grid-item, .my-shuffle-container.columns-4 .grid-item, .my-shuffle-container.columns-5 .grid-item, .my-shuffle-container.columns-6 .grid-item {
    width: 50%; }
  .portfolio .portfolio-slider .portfolio-item .portfolio-info {
    left: 15px;
    bottom: 0; }
    .portfolio .portfolio-slider .portfolio-item .portfolio-info .title {
      font-size: 24px; }
  .portfolio .portfolio-slider .portfolio-item:hover .portfolio-info {
    bottom: 0px; }
  .portfolio .portfolio-slider .portfolio-item:hover .portfolio-img {
    bottom: 0px; }
  .portfolio .portfolio-slider .portfolio-img {
    right: 15px; }
    .portfolio .portfolio-slider .portfolio-img i {
      font-size: 20px; }
  .portfolio .portfolio-slider .owl-nav {
    display: none; }
  .portfolio-post-navigation .portfolio-navigation .nav-link {
    display: block; }
    .portfolio-post-navigation .portfolio-navigation .nav-link .nav-previous a {
      justify-content: center; }
    .portfolio-post-navigation .portfolio-navigation .nav-link .grid-icon {
      text-align: center;
      margin: 20px 0; }
    .portfolio-post-navigation .portfolio-navigation .nav-link .nav-next a {
      justify-content: center; }
  .portfolio .portfolio-slider {
    margin-left: 0px; }
  .portfolio-slider.portfolio-slider-detail .owl-carousel .portfolio-item .portfolio-info {
    bottom: 30px; } }

@media (max-width: 575px) {
  .my-shuffle-container.columns-2 .grid-item, .my-shuffle-container .grid-item {
    width: 100%; }
  .my-shuffle-container .grid-item, .my-shuffle-container.columns-2 .grid-item, .my-shuffle-container.columns-3 .grid-item, .my-shuffle-container.columns-4 .grid-item, .my-shuffle-container.columns-5 .grid-item, .my-shuffle-container.columns-6 .grid-item {
    width: 100%; } }

/*****************************
Select
*****************************/
.select2-container .select2-selection--single {
  height: auto;
  outline: none; }
  .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 0px; }

.select2-container--default .select2-selection--single {
  border-radius: 0px;
  border: none; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #f0f6fa;
    font-weight: normal;
    line-height: inherit;
    text-transform: capitalize; }

.bigdrop {
  min-width: 196px !important; }

.select2-results__options {
  padding: 8px 2px; }

.select2-container--default .select2-results__option--highlighted {
  border-radius: 0;
  transition: all 0.2s ease-in-out; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  transition: all 0.3s ease-in-out; }

input[type="radio"]:radio {
  background-color: #1962a3; }

/* Select Dropdown Arrow */
.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 17px;
  right: 10px;
  height: 21px;
  width: 14px; }
  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border: none; }
  .select2-container--default .select2-selection--single .select2-selection__arrow:before {
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    color: #8d8c8c; }

.select2-container--default .select2-search--dropdown {
  padding: 0; }
  .select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #ededed; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #f0f6fa;
  transition: all 0.3s ease-in-out; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: rgba(38, 115, 248, 0.1);
  color: #1962a3;
  transition: all 0.3s ease-in-out; }

.select2-dropdown {
  border: none;
  border-radius: 0px;
  z-index: 9; }

.select-border .select2-container--default .select2-selection--single {
  border: 1px solid #f5f5f5;
  height: 50px;
  line-height: 20px;
  padding: 15px 20px;
  border-radius: 0;
  background-color: #f5f5f5; }
  .select-border .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 16px;
    right: 20px; }

.select2-results__option[aria-selected] {
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  font-size: 14px; }

.select2-container--default .select2-results > .select2-results__options {
  border: none; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #091c3a; }

.select2-container--open .select2-dropdown {
  background: #ffffff;
  padding: 7px;
  border: 1px solid rgba(61, 209, 213, 0.1);
  box-shadow: 0px 2px 5px 0px rgba(9, 28, 58, 0.1); }

.select2-search--dropdown .select2-search__field {
  border: 1px solid #ededed;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04); }

.select2-container--default .select2-selection--single {
  padding: 17px 20px; }

.custom-control-input:radio {
  background-color: #1962a3; }

.newsletter .search input::placeholder {
  color: #ffffff; }

.form-check .form-check-input {
  float: inherit;
  border-radius: 0; }

.form-check-input:checked {
  background-color: #1962a3;
  border-color: #1962a3;
  box-shadow: inherit; }

/*****************************
 Product
*****************************/
.product {
  position: relative; }
  .product .thumbnail-bg {
    background-color: #f5f5f5; }
  .product .thumbnail-light {
    background-color: #ffffff; }
  .product .product-image {
    position: relative; }
    .product .product-image img {
      transition: all 0.5s ease-in-out; }
      .product .product-image img:hover {
        transform: scale(1.05) rotate(0.01deg); }
    .product .product-image .custom-icon {
      position: absolute;
      bottom: 20px;
      right: 30px;
      opacity: 0;
      right: 10px;
      transition: all 0.3s ease-in-out; }
      .product .product-image .custom-icon ul li a {
        font-size: 18px;
        color: #091c3a;
        display: block;
        line-height: 42px;
        background: #ffffff;
        width: 40px;
        height: 40px;
        text-align: center;
        border-radius: 50%;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out; }
      .product .product-image .custom-icon ul li a.icon-bg {
        background: #f0f6fa; }
      .product .product-image .custom-icon ul li:hover a {
        color: #ffffff;
        background: #1962a3; }
  .product .product-content {
    padding: 30px 20px; }
    .product .product-content .product-info {
      display: flex;
      justify-content: space-between; }
      .product .product-content .product-info .product-title h3 {
        font-weight: 600;
        margin-bottom: 5px; }
      .product .product-content .product-info .product-star ul {
        display: flex; }
        .product .product-content .product-info .product-star ul li i {
          color: #ee9914;
          font-size: 15px; }
    .product .product-content .category {
      font-size: 18px;
      font-weight: 500;
      color: #091c3a; }
      .product .product-content .category:hover {
        color: #1962a3; }
    .product .product-content .product-prize {
      padding-top: 5px; }
      .product .product-content .product-prize p {
        color: #091c3a;
        font-weight: 500;
        margin-bottom: 0; }
        .product .product-content .product-prize p span {
          color: rgba(9, 28, 58, 0.6); }
  .product:hover .custom-icon {
    opacity: 1;
    right: 20px; }

/*****************************
  sidebar
*****************************/
.sidebar .widget {
  padding: 30px;
  margin-top: 0px !important;
  box-shadow: 0px 1px 30px 0px rgba(9, 28, 58, 0.09);
  margin-bottom: 30px;
  background: #ffffff;
  /*sidebar-search*/
  /*sidebar-categories*/
  /*RECENT POST*/
  /*social*/
  /*popular-tag*/ }
  .sidebar .widget .widget-title {
    margin-bottom: 20px; }
  .sidebar .widget .widget-content .popular-tag ul {
    margin-bottom: 0; }
  .sidebar .widget .search {
    position: relative;
    border-bottom: 1px solid #1962a3; }
    .sidebar .widget .search .btn {
      position: absolute;
      right: 15px;
      top: 20px;
      padding: 0;
      background: inherit;
      color: #091c3a;
      cursor: pointer;
      margin: 0;
      border-radius: 0 3px 3px 0px;
      transition: all 0.5s ease; }
    .sidebar .widget .search input {
      padding: 10px 90px 10px 20px;
      height: 50px;
      font-size: 18px;
      border: 0; }
  .sidebar .widget .widget-categories ul.list-style li a {
    display: flex;
    color: #091c3a;
    width: 100%;
    line-height: 32px;
    justify-content: space-between; }
  .sidebar .widget .widget-categories .list-style-underline li a {
    padding: 10px 0;
    border-bottom: 1px solid #ededed; }
    .sidebar .widget .widget-categories .list-style-underline li a span {
      font-size: 10px; }
    .sidebar .widget .widget-categories .list-style-underline li a:hover {
      color: #1962a3; }
  .sidebar .widget .widget-categories .list-style-underline li:last-child a {
    border-bottom: inherit; }
  .sidebar .widget .recent-post {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }
    .sidebar .widget .recent-post .post-img {
      flex: 0 0 100px;
      margin-right: 15px; }
    .sidebar .widget .recent-post .post-info .post-date {
      font-size: 14px;
      padding-bottom: 5px;
      display: block; }
      .sidebar .widget .recent-post .post-info .post-date i {
        margin-right: 8px; }
    .sidebar .widget .recent-post .post-info h6 {
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      margin-bottom: 0; }
  .sidebar .widget .social ul {
    margin-bottom: 0; }
    .sidebar .widget .social ul li {
      padding: 10px 10px 10px 15px;
      border: 1px solid #ededed;
      display: flex !important;
      margin-bottom: 10px;
      align-items: center; }
      .sidebar .widget .social ul li a {
        font-weight: 500; }
      .sidebar .widget .social ul li .follow {
        background: #f0f6fa;
        padding: 3px 18px;
        font-size: 12px; }
      .sidebar .widget .social ul li:last-child {
        margin-bottom: 0; }
    .sidebar .widget .social ul .facebook a {
      color: #466ca9; }
    .sidebar .widget .social ul .facebook .follow:hover {
      background: #466ca9;
      color: #ffffff; }
    .sidebar .widget .social ul .twitter a {
      color: #20b5e6; }
    .sidebar .widget .social ul .twitter .follow:hover {
      background: #20b5e6;
      color: #ffffff; }
    .sidebar .widget .social ul .youtube a {
      color: #d92c20; }
    .sidebar .widget .social ul .youtube .follow:hover {
      background: #d92c20;
      color: #ffffff; }
    .sidebar .widget .social ul .instagram a {
      color: #1962a3; }
    .sidebar .widget .social ul .instagram .follow:hover {
      background: #1962a3;
      color: #ffffff; }
    .sidebar .widget .social ul .linkedIn a {
      color: #13799f; }
    .sidebar .widget .social ul .linkedIn .follow:hover {
      background: #13799f;
      color: #ffffff; }
  .sidebar .widget .popular-tag ul li {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 8px; }
    .sidebar .widget .popular-tag ul li:last-child {
      margin-bottom: 0; }
    .sidebar .widget .popular-tag ul li a {
      padding: 6px 20px;
      display: block;
      border: 2px solid #f0f6fa;
      color: #8d8c8c;
      background: #f0f6fa; }
      .sidebar .widget .popular-tag ul li a:hover {
        color: #1962a3;
        border-color: #1962a3; }

@media (max-width: 575px) {
  .sidebar .widget {
    padding: 20px; } }

/*****************************
  Countdown
*****************************/
.countdown-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .countdown-main .countdown {
    padding: 10px;
    background-color: #091c3a;
    color: #ffffff;
    border-radius: 4px;
    margin: 5px;
    width: 66px;
    height: 66px; }
    .countdown-main .countdown span {
      font-size: 18px;
      line-height: 1;
      font-weight: bold;
      text-transform: capitalize; }
    .countdown-main .countdown p {
      font-size: 12px;
      text-transform: capitalize;
      margin-top: 5px;
      margin-bottom: 0; }

.coming-soon-section h1 {
  font-size: 100px;
  text-shadow: 0px 5px 8px rgba(0, 0, 0, 0.5); }

.coming-soon-section .countdown-main .countdown {
  color: #091c3a;
  background-color: inherit;
  width: inherit;
  height: inherit; }
  .coming-soon-section .countdown-main .countdown span {
    font-size: 60px;
    padding: 10px 30px;
    color: #091c3a;
    border-radius: 4px;
    background-color: #ffffff; }
  .coming-soon-section .countdown-main .countdown p {
    margin-top: 40px;
    font-size: 18px;
    padding: 6px 40px;
    color: #ffffff;
    border-radius: 6px;
    border: 1px solid #ffffff;
    font-weight: 500; }

.coming-soon-section .form-inline {
  position: relative; }
  .coming-soon-section .form-inline .form-control {
    height: 60px;
    padding: 14px 170px 14px 20px; }
  .coming-soon-section .form-inline .btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 14px 40px 14px;
    height: 60px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
    .coming-soon-section .form-inline .btn:after {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px; }
    .coming-soon-section .form-inline .btn:hover {
      transform: translateY(0px); }

.coming-soon-top .navbar .navbar-brand img {
  height: 70px; }

@media (max-width: 1200px) {
  .coming-soon-section h1 {
    font-size: 70px; }
  .coming-soon-section .countdown-main .countdown span {
    font-size: 60px; } }

@media (max-width: 991px) {
  .coming-soon {
    height: 100% !important; }
  .coming-soon-section h1 {
    font-size: 60px; }
  .coming-soon-section .countdown-main .countdown span {
    font-size: 50px; } }

@media (max-width: 767px) {
  .coming-soon-section h1 {
    font-size: 46px; }
  .coming-soon-section .countdown-main .countdown span {
    font-size: 40px; } }

@media (max-width: 575px) {
  .coming-soon-section h1 {
    font-size: 36px; }
  .coming-soon-section .form-inline .btn {
    position: inherit;
    border-radius: 6px !important; }
    .coming-soon-section .form-inline .btn:after {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px; } }

/*****************************
	Pie Chart
*****************************/
.pie-chart.pie-chart-percentage {
  display: flex;
  align-items: center; }
  .pie-chart.pie-chart-percentage .round-chart {
    display: flex;
    position: relative;
    text-align: center; }
    .pie-chart.pie-chart-percentage .round-chart span {
      display: inline-block;
      font-size: 22px;
      padding: 0;
      color: #ffffff;
      z-index: 2;
      font-weight: 600;
      position: absolute; }
    .pie-chart.pie-chart-percentage .round-chart .percent:after {
      content: "%"; }
  .pie-chart.pie-chart-percentage .chart-title {
    font-size: 22px;
    font-weight: 600;
    margin-left: 15px; }

/*****************************
  Header Default
*****************************/
.header {
  position: absolute;
  z-index: 11;
  width: 100%;
  padding: 0px 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
  .header .navbar .navbar-brand {
    padding-right: 35px;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    margin-right: 30px; }
    .header .navbar .navbar-brand .logo {
      height: 40px; }
    .header .navbar .navbar-brand .sticky-logo {
      display: none; }
  .header .navbar .navbar-nav {
    position: relative; }
    .header .navbar .navbar-nav .nav-item {
      margin-right: 30px; }
      .header .navbar .navbar-nav .nav-item:last-child {
        margin-right: 0; }
      .header .navbar .navbar-nav .nav-item .nav-link {
        color: #ffffff;
        font-family: "Outfit", sans-serif;
        font-size: 16px;
        display: flex;
        align-items: center;
        transition: all 0.3s ease-in-out;
        padding: 30px 5px;
        font-weight: 500;
        text-transform: uppercase; }
        .header .navbar .navbar-nav .nav-item .nav-link i {
          margin-left: 8px; }
        .header .navbar .navbar-nav .nav-item .nav-link:hover {
          color: #1962a3; }
    .header .navbar .navbar-nav li.active > .nav-link {
      color: #1962a3; }
  .header .navbar .add-listing {
    display: flex;
    align-items: center; }
    .header .navbar .add-listing .need-help {
      display: flex;
      align-items: center;
      padding-right: 50px;
      border-right: 1px solid rgba(255, 255, 255, 0.3);
      height: 50px; }
      .header .navbar .add-listing .need-help .icon {
        width: 50px;
        background: #1962a3;
        height: 50px;
        border-radius: 100%;
        padding: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px; }
        .header .navbar .add-listing .need-help .icon img {
          width: 24px; }
      .header .navbar .add-listing .need-help .help-info p {
        margin-bottom: 5px;
        color: #ffffff; }
      .header .navbar .add-listing .need-help .help-info span {
        color: #ffffff;
        font-size: 18px; }
    .header .navbar .add-listing .search-box {
      width: fit-content;
      height: fit-content;
      position: relative;
      display: flex;
      align-items: center; }
    .header .navbar .add-listing .input-search {
      height: 50px;
      width: 50px;
      border-style: none;
      padding: 10px;
      font-size: 18px;
      letter-spacing: 2px;
      outline: none;
      border-radius: 25px;
      transition: all .5s ease-in-out;
      background-color: inherit;
      padding-right: 40px;
      color: #ffffff; }
      .header .navbar .add-listing .input-search::placeholder {
        color: rgba(255, 255, 255, 0.5);
        font-size: 18px;
        letter-spacing: 2px;
        font-weight: 100; }
    .header .navbar .add-listing .btn-search {
      border-style: none;
      font-size: 16px;
      font-weight: bold;
      outline: none;
      cursor: pointer;
      border-radius: 50%;
      position: absolute;
      right: 5px;
      color: #ffffff;
      background-color: transparent;
      pointer-events: painted; }
      .header .navbar .add-listing .btn-search:focus ~ .input-search {
        width: 300px;
        border-radius: 0px;
        background-color: transparent;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2); }
    .header .navbar .add-listing .input-search:focus {
      width: 300px;
      border-radius: 0px;
      background-color: transparent;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2); }
    .header .navbar .add-listing .woo-action {
      padding: 10px 40px;
      border-right: 1px solid rgba(255, 255, 255, 0.3); }
      .header .navbar .add-listing .woo-action ul {
        margin: 0; }
        .header .navbar .add-listing .woo-action ul li {
          display: inline-block;
          margin-right: 20px; }
          .header .navbar .add-listing .woo-action ul li:last-child {
            margin-right: 0px; }
          .header .navbar .add-listing .woo-action ul li a {
            color: #091c3a;
            font-size: 12px;
            position: relative; }
            .header .navbar .add-listing .woo-action ul li a:hover {
              color: #1962a3; }
          .header .navbar .add-listing .woo-action ul li .cart-count {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -15px;
            right: -8px;
            width: 16px;
            height: 16px;
            font-size: 10px;
            color: #ffffff;
            background: #1962a3;
            border-radius: 50%; }
          .header .navbar .add-listing .woo-action ul li .cart-icon {
            font-size: 20px;
            color: #ffffff; }
        .header .navbar .add-listing .woo-action ul li.cart .dropdown-toggle {
          background: transparent;
          border: none;
          color: #091c3a;
          transition: all 0.3s ease-in-out;
          width: 33px;
          margin-top: 5px; }
          .header .navbar .add-listing .woo-action ul li.cart .dropdown-toggle object {
            width: 23px;
            filter: invert(10%) sepia(8%) saturate(5323%) hue-rotate(164deg) brightness(98%) contrast(92%);
            cursor: pointer;
            transition: all 0.3s ease-in-out; }
          .header .navbar .add-listing .woo-action ul li.cart .dropdown-toggle:hover {
            color: #1962a3; }
            .header .navbar .add-listing .woo-action ul li.cart .dropdown-toggle:hover object {
              filter: invert(84%) sepia(25%) saturate(1301%) hue-rotate(134deg) brightness(90%) contrast(84%);
              transition: all 0.3s ease-in-out; }
          .header .navbar .add-listing .woo-action ul li.cart .dropdown-toggle:after {
            border-top: none; }
        .header .navbar .add-listing .woo-action ul li.cart:hover .dropdown-menu-right {
          transform: translate3d(0%, 0, 0);
          visibility: visible;
          opacity: 1; }
    .header .navbar .add-listing .side-menu {
      padding: 10px 0px 10px 40px; }
      .header .navbar .add-listing .side-menu img {
        width: 24px; }
      .header .navbar .add-listing .side-menu .menu-dark {
        display: none; }

header.header.header-sticky.is-sticky {
  position: fixed;
  background: #ffffff;
  transition: all 0.5s ease-in-out;
  box-shadow: 0px 1px 30px 0px rgba(9, 28, 58, 0.09);
  top: -110px; }
  header.header.header-sticky.is-sticky.sticky-show {
    top: 0; }
  header.header.header-sticky.is-sticky .navbar .navbar-nav .nav-item .nav-link {
    color: #091c3a; }
  header.header.header-sticky.is-sticky .navbar .navbar-nav .nav-item.active .nav-link {
    color: #1962a3; }
  header.header.header-sticky.is-sticky .navbar .navbar-brand {
    border-right: 1px solid rgba(9, 28, 58, 0.3); }
    header.header.header-sticky.is-sticky .navbar .navbar-brand .logo {
      display: none; }
    header.header.header-sticky.is-sticky .navbar .navbar-brand .sticky-logo {
      display: flex;
      height: 40px; }
  header.header.header-sticky.is-sticky .navbar .add-listing .need-help {
    border-right: 1px solid rgba(9, 28, 58, 0.3); }
    header.header.header-sticky.is-sticky .navbar .add-listing .need-help .help-info p {
      color: #091c3a; }
    header.header.header-sticky.is-sticky .navbar .add-listing .need-help .help-info span {
      color: #091c3a; }
  header.header.header-sticky.is-sticky .navbar .add-listing .woo-action {
    border-right: 1px solid rgba(9, 28, 58, 0.3); }
    header.header.header-sticky.is-sticky .navbar .add-listing .woo-action ul li a .cart-icon {
      color: #091c3a; }
  header.header.header-sticky.is-sticky .navbar .add-listing .side-menu img {
    display: none; }
  header.header.header-sticky.is-sticky .navbar .add-listing .side-menu .menu-dark {
    display: flex; }

.header.header-sticky.is-sticky .navbar .navbar-toggler {
  border: inherit;
  color: #000000; }

/*Header Style 02*/
.header.header-style-02 .navbar .add-listing .woo-action {
  padding: 10px 20px;
  border: none; }

.header.header-style-02 .navbar .add-listing .side-menu {
  padding: 10px 0px 10px 20px; }

.header.header-style-02 .navbar .add-listing .need-help {
  border: none; }

.header.header-style-02 .navbar .navbar-brand {
  border: none;
  position: relative; }
  .header.header-style-02 .navbar .navbar-brand::after {
    content: "";
    background: rgba(255, 255, 255, 0.3);
    height: 104px;
    width: 1px;
    position: absolute;
    right: 0;
    top: -58%; }

.header.header-style-02 .navbar .navbar-nav:after {
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 100px;
  width: 1px;
  position: absolute;
  right: -30px;
  top: -10%; }

.header.header-style-02 .search-product {
  position: relative;
  margin-right: 15px;
  display: inline-block; }
  .header.header-style-02 .search-product .form-control {
    font-size: 16px;
    line-height: 1;
    border-bottom: 1px solid #f5f5f5;
    padding: 10px 65px 10px 10px;
    width: 200px;
    background: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: inherit;
    color: #ffffff;
    height: 40px; }
  .header.header-style-02 .search-product .search-button {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 14px;
    position: absolute;
    right: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    color: #ffffff;
    text-transform: uppercase; }
    .header.header-style-02 .search-product .search-button:hover {
      color: #1962a3; }

header.header.header-style-02.header-sticky.is-sticky .search-product .form-control {
  border-bottom: 1px solid #091c3a;
  color: #091c3a; }

header.header.header-style-02.header-sticky.is-sticky .search-product .search-button {
  color: #1962a3; }
  header.header.header-style-02.header-sticky.is-sticky .search-product .search-button:hover {
    color: #091c3a; }

header.header.header-style-02.header-sticky.is-sticky .navbar .navbar-brand {
  border-right: none; }
  header.header.header-style-02.header-sticky.is-sticky .navbar .navbar-brand:after {
    background: #ededed; }

header.header.header-style-02.header-sticky.is-sticky .navbar .navbar-nav:after {
  background: #ededed; }

header.header.header-style-02.header-sticky.is-sticky .navbar .add-listing .woo-action {
  border-right: none; }

/*Header Style 03*/
.header.header-style-03 .navbar .navbar-brand {
  border-right: 0;
  margin-right: 0;
  padding-right: 0; }

.header.header-style-03 .navbar .navbar-collapse {
  justify-content: center; }

.header.header-style-03 .navbar .add-listing .woo-action {
  padding: 0;
  border: none; }

.header.header-style-03 .navbar .add-listing .side-menu {
  padding: 10px 30px 10px 40px; }

.header.header-style-03.header-sticky.is-sticky .navbar .navbar-brand {
  border-right: 0; }

.header.header-style-03.header-sticky.is-sticky .navbar .add-listing .woo-action {
  border-right: 0; }

.cart-side-menu .offcanvas-header {
  border-bottom: 1px solid #ededed;
  padding: 20px 15px; }
  .cart-side-menu .offcanvas-header .btn-close:focus {
    box-shadow: none; }

.cart-side-menu .offcanvas-body {
  padding: 0; }
  .cart-side-menu .offcanvas-body .dropdown-menu-right {
    height: 100%;
    display: flex;
    align-content: space-between;
    flex-wrap: wrap; }
    .cart-side-menu .offcanvas-body .dropdown-menu-right .cart-list {
      padding: 15px 15px;
      width: 100%; }
    .cart-side-menu .offcanvas-body .dropdown-menu-right .cart-list li {
      margin-right: 0;
      padding: 15px 40px 0px 0px;
      position: relative;
      display: flex;
      align-items: flex-start; }
      .cart-side-menu .offcanvas-body .dropdown-menu-right .cart-list li:last-child {
        border-bottom: 1px solid #ededed;
        padding-bottom: 30px; }
      .cart-side-menu .offcanvas-body .dropdown-menu-right .cart-list li .cart-info a {
        font-size: 16px;
        font-weight: 500;
        color: #091c3a; }
        .cart-side-menu .offcanvas-body .dropdown-menu-right .cart-list li .cart-info a:hover {
          color: #1962a3; }
      .cart-side-menu .offcanvas-body .dropdown-menu-right .cart-list li .remove-item {
        position: absolute;
        right: 0px;
        top: 15px;
        border-radius: 100%;
        height: 25px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #091c3a; }
        .cart-side-menu .offcanvas-body .dropdown-menu-right .cart-list li .remove-item:hover {
          border-color: #1962a3;
          color: #1962a3; }
      .cart-side-menu .offcanvas-body .dropdown-menu-right .cart-list li img {
        width: 80px;
        flex: 0 0 80px;
        height: auto;
        background: #f0f6fa; }

.cart-side-menu .cart-footer {
  padding: 15px;
  position: sticky;
  bottom: 0;
  background: #ffffff;
  border-top: 1px solid #ededed;
  box-shadow: 0 -4px 20px 0px rgba(9, 28, 58, 0.07);
  width: 100%; }
  .cart-side-menu .cart-footer a {
    display: inline-block;
    text-align: center; }
  .cart-side-menu .cart-footer a.btn {
    color: #ffffff; }
  .cart-side-menu .cart-footer span {
    font-size: 18px;
    font-weight: 600; }

.offcanvas.offcanvas-end.offcanvas-sidebar-menu {
  left: 0;
  right: inherit;
  transform: translateX(-100%);
  backdrop-filter: blur(13px) !important;
  background: rgba(9, 28, 58, 0.12) !important;
  width: 500px; }
  .offcanvas.offcanvas-end.offcanvas-sidebar-menu .offcanvas-header .btn-close {
    background: none;
    opacity: 1; }
    .offcanvas.offcanvas-end.offcanvas-sidebar-menu .offcanvas-header .btn-close i {
      font-size: 28px;
      color: #ffffff; }
    .offcanvas.offcanvas-end.offcanvas-sidebar-menu .offcanvas-header .btn-close:focus {
      box-shadow: none; }

.offcanvas.offcanvas-sidebar-menu.show {
  transform: none; }

.header .navbar .dropdown > .dropdown-menu li.active a {
  color: #1962a3; }

@media (max-width: 1500px) {
  .header .navbar .navbar-brand {
    padding-right: 30px;
    margin-right: 20px; }
  .header .navbar .navbar-nav .nav-item {
    margin-right: 15px; }
  .header .navbar .add-listing .need-help {
    padding-right: 30px; }
  .header .navbar .add-listing .woo-action {
    padding: 10px 25px 10px 20px; }
  .header .navbar .add-listing .side-menu {
    padding: 10px 0px 10px 20px; } }

@media (max-width: 1366px) {
  .header .navbar .navbar-nav .nav-item {
    margin-right: 8px; }
  .header .navbar-brand {
    flex: 0 0 150px; } }

@media (max-width: 1300px) {
  .header {
    padding: 0px 20px; } }

@media (min-width: 1200px) {
  .header .navbar .dropdown-menu {
    margin: 0px;
    min-width: 200px;
    font-size: 16px;
    border-radius: 6px;
    padding: 20px;
    border-color: transparent;
    left: 0;
    transform: translate3d(-10px, 15px, 0);
    transition: all 0.3s ease-in-out;
    transition-property: opacity, visibility, transform;
    transform-origin: top center;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    visibility: hidden;
    opacity: 0;
    display: block !important; }
    .header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu {
      left: 100%;
      right: auto;
      transform: translate3d(0, 10px, 0); }
    .header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu.left-side {
      right: 100%;
      left: auto;
      transform: translate3d(-15px, 10px, 0); }
  .header .navbar .dropdown-menu.megamenu {
    left: 0; }
  .header .navbar .dropdown > .dropdown-menu {
    top: 100%;
    margin-top: 0px; }
  .header .navbar .dropdown > .dropdown-menu.megamenu {
    top: 100%; }
  .header .navbar .dropdown:hover > .dropdown-menu {
    transform: translate3d(-10px, 0px, 0);
    visibility: visible;
    opacity: 1; }
  .navbar-nav .dropdown-menu.dropdown-menu-lg {
    min-width: 630px; }
  .navbar-nav .dropdown-menu.dropdown-menu-md {
    min-width: 450px; }
  .navbar-nav .mega-menu {
    position: static; }
  .navbar-nav li:hover > ul.dropdown-menu {
    visibility: visible;
    opacity: 1; }
  .dropdown-submenu {
    position: relative;
    position: relative;
    padding: 7px 0 7px 0;
    display: block;
    color: #091c3a; }
    .dropdown-submenu > .dropdown-menu {
      top: 0;
      left: 100%;
      margin-top: -6px; }
  .dropdown-menu > li > a:hover:after {
    text-decoration: underline;
    transform: rotate(-90deg); }
  .dropdown-menu .dropdown-item {
    padding: 7px 0 7px 0;
    background: none; }
    .dropdown-menu .dropdown-item:hover {
      color: #1962a3; }
    .dropdown-menu .dropdown-item:focus {
      background-color: transparent; } }

@media (max-width: 1199px) {
  .header .navbar-collapse {
    position: absolute;
    top: 60px;
    z-index: 999;
    background: #ffffff;
    width: 100%;
    max-height: 350px;
    overflow: auto;
    left: 0;
    margin-top: -1px;
    margin-left: 0px;
    border-radius: 6px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .header .navbar .navbar-nav {
    align-items: initial; }
    .header .navbar .navbar-nav .nav-item {
      margin-right: 0; }
      .header .navbar .navbar-nav .nav-item .nav-link {
        color: #091c3a;
        padding: 10px 20px;
        font-size: 16px; }
        .header .navbar .navbar-nav .nav-item .nav-link i {
          position: absolute;
          right: 20px; }
    .header .navbar .navbar-nav .dropdown.mega-menu .megamenu {
      width: 95%;
      margin: 0 auto; }
  .header .navbar .dropdown-menu {
    margin: 0px 15px;
    font-size: 14px;
    border-radius: 0px;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #f0f6fa;
    padding: 15px; }
    .header .navbar .dropdown-menu .dropdown-item {
      padding: 10px 0px; }
  .header .navbar .nav-title {
    margin-top: 20px; }
  .header .navbar .navbar-toggler {
    position: absolute;
    left: 190px;
    box-shadow: none;
    transition: 0.5s ease-in-out;
    background: none;
    color: #ffffff;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .header .navbar .add-listing {
    padding-right: 0px; }
    .header .navbar .add-listing .menu-btn {
      display: none; }
  .header.header-style-02 .navbar .navbar-brand::after {
    height: 90px;
    top: -65%; }
  .header.header-style-02 .navbar .navbar-nav:after {
    content: none; } }

@media (max-width: 991px) {
  .header .navbar .navbar-brand {
    padding-right: 20px; } }

@media (max-width: 767px) {
  .header .navbar .navbar-brand .logo {
    height: 35px; }
  .header .navbar .add-listing .need-help {
    display: none; }
  .header .navbar .add-listing .search-box {
    display: none; }
  .header.header-style-02 .navbar .navbar-brand::after {
    height: 85px; }
  .header.header-style-02 .search-product .form-control {
    width: 170px; } }

@media (max-width: 575px) {
  .header.header-style-02 .add-listing .search-product {
    display: none; }
  .header.header-style-03 .navbar .add-listing .side-menu {
    padding: 10px 0px 10px 40px; }
  .header.header-style-03 .navbar .add-listing .btn {
    display: none; } }

@media (max-width: 479px) {
  .header {
    padding: 0px 10px; }
    .header .navbar .navbar-toggler {
      left: 150px; }
    .header .navbar .navbar-brand {
      flex: 0 0 120px; } }

/*****************************
    layout css
*****************************/
/*Section Title*/
.section-title {
  margin-bottom: 50px; }
  .section-title span {
    margin-bottom: 10px;
    color: #1962a3;
    font-size: 16px;
    font-weight: 600;
    display: inline-flex;
    text-transform: uppercase; }
  .section-title h2 {
    font-weight: 600;
    margin-bottom: 0; }
  .section-title p {
    margin-top: 20px; }

.left-divider span {
  position: relative;
  padding-left: 55px; }
  .left-divider span:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 3px;
    background: #1962a3; }

/*Video*/
.video-image {
  position: relative;
  overflow: hidden;
  box-shadow: 0px 1px 30px 0px rgba(9, 28, 58, 0.09); }
  .video-image img {
    transition: all 0.5s ease-in-out; }
  .video-image .video-btn {
    position: absolute;
    top: 100px;
    background-color: #ffffff;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    right: 0;
    transform: translate(-50%, -50%); }
    .video-image .video-btn i {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      background: #1962a3;
      border-radius: 50%;
      color: #ffffff;
      top: 9px;
      left: 8px;
      font-size: 28px;
      position: absolute;
      transition: all 0.3s ease-in-out; }
  .video-image:hover img {
    transform: scale(1.05) rotate(0.01deg); }
  .video-image:hover .video-btn {
    display: flex;
    align-items: center;
    justify-content: center; }
    .video-image:hover .video-btn i {
      top: 0;
      left: 5px;
      transform: translateY(5px); }

.video-box {
  position: relative; }
  .video-box .video-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%); }
    .video-box .video-btn:before {
      content: "";
      position: absolute;
      z-index: 0;
      transform: translateX(-50%) translateY(-50%);
      display: block;
      width: 60px;
      height: 60px;
      left: 50%;
      top: 50%;
      border: 1px solid;
      border-radius: 50%;
      animation: pulse-border 1.5s ease-out infinite; }
    .video-box .video-btn i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      background: #1962a3;
      transition: all 200ms;
      font-size: 22px;
      color: #ffffff;
      border-radius: 50%; }

.video-bg-section .video-image .video-btn {
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  background-color: rgba(38, 115, 248, 0.6); }
  .video-bg-section .video-image .video-btn i {
    background-color: #ffffff;
    color: #1962a3; }

.video-bg-section .video-wrapper {
  height: 700px;
  overflow: hidden;
  align-items: center;
  display: flex; }
  .video-bg-section .video-wrapper video {
    width: 100%;
    align-items: center; }

/*keyframes*/
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1; }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0; } }

@keyframes sonarWave {
  from {
    opacity: 0.4; }
  to {
    transform: scale(3);
    opacity: 0; } }

.back-to-top a {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 50px;
  right: 40px;
  width: 50px;
  height: 50px;
  margin: 0px;
  color: #ffffff;
  font-size: 18px;
  background: #1962a3;
  transition: all 0.3s ease-in-out;
  z-index: 999; }

/*Clients*/
.clients-section {
  padding: 100px 0; }
  .clients-section .owl-stage {
    display: flex;
    align-items: center; }
    .clients-section .owl-stage a {
      display: flex;
      justify-content: center; }
      .clients-section .owl-stage a img {
        width: auto !important;
        padding: 20px; }

.clients-grid .client-box {
  position: relative;
  overflow: hidden;
  margin-bottom: 80px;
  display: inline-block;
  text-align: center; }
  .clients-grid .client-box .client-hover-img {
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0;
    right: 0;
    transform: translateY(-100%);
    transition: transform .4s ease, -webkit-transform .4s ease; }
  .clients-grid .client-box .client-img {
    transform: translateY(0%);
    transition: transform .4s ease, -webkit-transform .4s ease; }
  .clients-grid .client-box:hover .client-hover-img {
    visibility: visible;
    transform: translateY(0%);
    top: 0; }
  .clients-grid .client-box:hover .client-img {
    visibility: visible;
    transform: translateY(100%); }

.clients-grid .client-box {
  margin-bottom: 80px;
  display: inline-block;
  text-align: center;
  padding: 0 60px; }

.client-box {
  position: relative;
  overflow: hidden; }
  .client-box .client-hover-img {
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0;
    right: 0;
    transform: translateY(-100%);
    transition: transform .4s ease, -webkit-transform .4s ease; }
  .client-box .client-img {
    transform: translateY(0%);
    transition: transform .4s ease, -webkit-transform .4s ease; }
  .client-box:hover .client-hover-img {
    visibility: visible;
    transform: translateY(0%);
    top: 0; }
  .client-box:hover .client-img {
    visibility: visible;
    transform: translateY(100%); }

/*****************************
 list
*****************************/
.list ul li {
  margin-bottom: 15px;
  font-weight: 500;
  display: flex;
  align-items: baseline; }
  .list ul li i {
    color: #1962a3; }

/*Home*/
.ev-car-area {
  display: flex;
  flex-wrap: wrap; }
  .ev-car-area .car-area {
    text-align: center;
    width: 25%; }
    .ev-car-area .car-area h2 {
      color: #ffffff; }
      .ev-car-area .car-area h2 span {
        color: #1962a3; }
    .ev-car-area .car-area span {
      color: #ffffff; }
  .ev-car-area .car-area.car-border-area {
    border-right: 3px solid #657082; }
    .ev-car-area .car-area.car-border-area:last-child {
      border-right: 0; }

/*Home 02*/
.bg-secondary .btn-white:hover {
  background: #1962a3;
  color: #ffffff; }

/*Home 03*/
.service-section .service-description {
  padding: 50px 50px 50px 120px; }
  .service-section .service-description .section-title {
    margin-bottom: 30px; }
  .service-section .service-description .service-box .service-icon object {
    margin-left: -25px;
    width: 120px;
    filter: invert(40%) sepia(39%) saturate(7437%) hue-rotate(210deg) brightness(100%) contrast(95%);
    padding-bottom: 0px; }
  .service-section .service-description .service-box .service-content h4 {
    margin-bottom: 15px; }
  .service-section .service-description .service-box .service-content p {
    margin-bottom: 0; }

.feature-service-box .owl-stage-outer {
  padding-top: 5px; }

.maintenance-section .maintenance-box {
  padding-right: 35px; }
  .maintenance-section .maintenance-box .list-style-01 ul li {
    color: #091c3a; }
  .maintenance-section .maintenance-box .btn {
    margin-top: 40px; }
    .maintenance-section .maintenance-box .btn i {
      margin-left: 10px; }

.service-testimonial form {
  padding: 100px 50px 100px 300px; }

.newsletter-section .newsletter form .btn.btn-link:hover {
  color: #091c3a; }

/*Home 03*/
.ev-car-section {
  padding: 100px 0; }
  .ev-car-section:before {
    z-index: -1; }
  .ev-car-section .ev-car-area {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    margin-right: -100px; }
    .ev-car-section .ev-car-area .car-area {
      width: 20%;
      margin-bottom: 80px; }
      .ev-car-section .ev-car-area .car-area:last-child {
        margin-bottom: 0; }

.sidebar-menu .sidebar-logo a img {
  height: 40px; }

.sidebar-menu .social-icon {
  position: fixed;
  bottom: 30px; }
  .sidebar-menu .social-icon ul {
    padding: 0; }
    .sidebar-menu .social-icon ul li {
      display: inline-flex;
      padding-right: 20px; }
      .sidebar-menu .social-icon ul li:last-child {
        padding-right: 0; }
      .sidebar-menu .social-icon ul li a {
        font-size: 24px;
        color: #ffffff; }
        .sidebar-menu .social-icon ul li a:hover {
          color: #1962a3; }
  .sidebar-menu .social-img ul li a:hover {
      color: #1962a3; }

.about .section-title {
  margin-right: 200px; }

.about .about-right {
  padding-left: 60px; }
  .about .about-right .experiense-counter {
    position: absolute;
    left: -90px;
    top: 50px;
    width: 250px; }
    .about .about-right .experiense-counter .counter {
      background: #1962a3;
      padding: 40px 30px; }
      .about .about-right .experiense-counter .counter .counter-number {
        display: flex;
        text-align: left; }
        .about .about-right .experiense-counter .counter .counter-number p {
          font-weight: 400;
          display: flex;
          margin-left: 10px;
          align-items: center;
          line-height: 1.5; }
        .about .about-right .experiense-counter .counter .counter-number h2.title {
          font-size: 80px;
          line-height: 90px;
          font-weight: 600;
          color: #ffffff; }
  .about .about-right .about-counter .counter-info {
    display: flex;
    align-items: center;
    width: 50%; }
    .about .about-right .about-counter .counter-info h2 {
      font-size: 60px;
      font-weight: 600;
      line-height: 66px; }

.video-overlay {
  margin-top: -200px;
  position: relative;
  z-index: 1; }

/*Inner Header*/
.inner-header {
  height: 500px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 60px; }

.inner-header-tittle h2 {
  color: #ffffff;
  margin-bottom: 15px;
  font-size: 60px;
  line-height: 70px;
  position: relative;
  padding-bottom: 40px; }
  .inner-header-tittle h2:before {
    position: absolute;
    content: "";
    bottom: 0px;
    width: 100px;
    height: 1px;
    background: #ffffff; }

.inner-header-tittle .breadcrumb {
  margin-bottom: 0; }
  .inner-header-tittle .breadcrumb .breadcrumb-item {
    color: #ffffff;
    text-transform: capitalize; }
    .inner-header-tittle .breadcrumb .breadcrumb-item:before {
      color: #ffffff; }
    .inner-header-tittle .breadcrumb .breadcrumb-item a {
      color: #ffffff; }
      .inner-header-tittle .breadcrumb .breadcrumb-item a:hover {
        color: #1962a3; }

/*Contact Us*/
.message-info {
  padding: 40px 30px; }
  .message-info .section-title h2 {
    font-size: 34px; }

textarea.form-control {
  height: auto; }

.contact-us-form {
  padding-left: 60px; }
  .contact-us-form .form-control {
    border: 1px solid #ededed; }
  .contact-us-form .select2-container--default {
    border: 1px solid #ededed; }

/*workplaces*/
.workplace-banner .section-title {
  padding: 30% 15% 16% 15%; }
  .workplace-banner .section-title .btn {
    margin-top: 50px; }

.workplaces-service-box .feature-item.feature-info-style-01 .feature-content h4 {
  color: #ffffff; }

.workplaces-service-box .feature-item.feature-info-style-01 .feature-content p {
  color: #ffffff; }

/*404*/
.error-404 .error-content .fourofour {
  font-size: 400px;
  color: #f5f5f5;
  line-height: 300px; }

.error-404 .error-content img {
  margin-top: -180px; }

.error-404 .error-content h3 {
  font-size: 60px;
  margin-bottom: 20px;
  font-weight: bold; }

.error-404 .error-content strong {
  font-style: italic;
  font-size: 20px;
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #091c3a; }

.error-404 .error-content .btn {
  margin-top: 30px; }

/* pagination */
.pagination {
  display: flex; }
  .pagination .page-item {
    margin: 0 6px;
    display: flex; }
    .pagination .page-item .page-link {
      color: #091c3a;
      padding: 10px 18px;
      border-radius: 0;
      font-weight: 500;
      border: none;
      background: #f5f5f5;
      border-color: #f5f5f5; }
      .pagination .page-item .page-link:hover {
        background: #1962a3;
        border-color: #1962a3;
        color: #ffffff; }
      .pagination .page-item .page-link:focus {
        background: #1962a3;
        border-color: #1962a3;
        color: #ffffff;
        box-shadow: none; }

.page-item.active .page-link {
  background: #1962a3;
  border-color: #1962a3;
  color: #ffffff; }

/*blockquote*/
.blockquote {
  font-size: 16px;
  border-left: 3px solid #eeeeee;
  padding-left: 25px;
  font-style: italic;
  display: block; }

/*Prevent text selection*/
.qty {
  border: 1px solid rgba(9, 28, 58, 0.2);
  margin-right: 10px;
  align-items: center;
  display: flex;
  padding: 9px 10px;
  border-radius: 0;
  width: 170px;
  justify-content: center;
  justify-content: space-between; }
  .qty .count {
    color: #091c3a;
    display: inline-block;
    vertical-align: top;
    font-size: 18px;
    line-height: 10px;
    padding: 0 2px;
    min-width: 35px;
    text-align: center; }
  .qty .plus {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    font-size: 30px;
    line-height: 30px;
    color: rgba(9, 28, 58, 0.2);
    min-width: 30px;
    text-align: center; }
  .qty input {
    color: #ffffff;
    border-color: inherit;
    text-align: center;
    width: auto; }
  .qty .minus {
    cursor: pointer;
    display: inline-block;
    font-size: 30px;
    line-height: 30px;
    min-width: 30px;
    color: rgba(9, 28, 58, 0.2);
    text-align: center; }
  .qty input {
    border: 0;
    width: 2%; }
    .qty input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .qty input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .qty input:disabled {
      background-color: white; }

.coming-soon-section .coming-soon-logo img {
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
  height: 50px; }

.station-blog {
  padding-top: 170px; }

/*Map Listing*/
.map-listing {
  position: relative; }
  .map-listing .contant .map-img {
    width: 160px;
    height: auto;
    background-color: #ffffff;
    padding: 10px;
    box-shadow: 0px 1px 30px 0px rgba(9, 28, 58, 0.09);
    margin-bottom: 15px;
    border-radius: 6px;
    position: relative;
    z-index: 9; }
    .map-listing .contant .map-img img {
      border-radius: 6px; }
  .map-listing .contant i {
    font-size: 18px;
    color: #091c3a; }
  .map-listing .contant .icon {
    justify-content: center;
    text-align: center;
    cursor: pointer; }
    .map-listing .contant .icon:hover .map-img {
      opacity: 1; }
    .map-listing .contant .icon .map-img {
      opacity: 0;
      transition: all 0.3s ease-out 0s;
      cursor: pointer; }
  .map-listing .map-icon.icon-01 {
    position: absolute;
    top: 5%;
    left: 10%;
    transform: translateY(-50%); }
  .map-listing .map-icon.icon-02 {
    position: absolute;
    top: -7%;
    left: 59%; }
  .map-listing .map-icon.icon-03 {
    position: absolute;
    top: 12%;
    left: 18%; }

@media (min-width: 1400px) and (max-width: 1600px) {
  .service-testimonial form {
    padding: 0px 50px 0px 50px; }
  .ev-car-section .ev-car-area {
    margin-right: 0; } }

@media (max-width: 1400px) {
  .service-testimonial form {
    padding: 30px; }
  .get-charging .section-title h2 {
    font-size: 42px; }
  /*Map Listing*/
  .map-listing .map-icon.icon-01 {
    position: absolute;
    top: 2%;
    left: 6%;
    transform: translateY(-50%); }
  .map-listing .map-icon.icon-02 {
    position: absolute;
    top: -12%;
    left: 57%; }
  .map-listing .map-icon.icon-03 {
    position: absolute;
    top: 5%;
    left: 15%; } }

@media (max-width: 1300px) {
  .service-testimonial form {
    padding: 30px; }
  .about .section-title {
    margin-right: 50px; }
  .about .about-right .experiense-counter {
    left: -50px; }
  .video-bg-section {
    height: inherit; }
    .video-bg-section .video-wrapper {
      height: inherit; }
  .ev-car-section .ev-car-area {
    margin-right: 0; } }

@media (max-width: 1200px) {
  /*Home 03*/
  .service-section .service-description {
    padding: 50px; }
  .service-testimonial form {
    padding: 40px; }
  /*inner-header*/
  .inner-header {
    height: 540px; }
  .get-charging .middle-img {
    width: 50%; }
  .ev-car-area .car-area h2 {
    font-size: 32px; }
  /*Map Listing*/
  .map-listing .map-icon.icon-01 {
    position: absolute;
    top: 0%;
    left: 2%;
    transform: translateY(-50%); }
  .map-listing .map-icon.icon-02 {
    position: absolute;
    top: -16%;
    left: 54%; }
  .map-listing .map-icon.icon-03 {
    position: absolute;
    top: 2%;
    left: 15%; }
  .error-404 .error-content .fourofour {
    font-size: 330px;
    line-height: 300px; } }

@media (max-width: 1024px) {
  .map-section .map-icon.icon-03 {
    left: 10%; } }

@media (max-width: 991px) {
  .section-title {
    margin-bottom: 40px; }
    .section-title p {
      margin-top: 15px; }
  .get-charging .section-title {
    position: inherit;
    width: 100%;
    transform: translateY(0%) !important;
    margin-bottom: 40px; }
    .get-charging .section-title h2 {
      font-size: 36px; }
  /*workplaces*/
  .workplace-banner .section-title {
    padding: 20% 10% 16% 10%; }
  .workplace-banner .workplace-bg {
    height: 500px !important; }
  .workplace-banner.software-banner .section-title {
    padding: 15% 10% 15% 10%; }
  /*inner-header*/
  .inner-header {
    height: 440px; }
  .inner-header-tittle h2 {
    font-size: 50px;
    line-height: 60px;
    padding-bottom: 20px; }
  .ev-car-area .car-area {
    width: 50%;
    margin-bottom: 30px;
    border: inherit; }
  .ev-car-area .car-area.car-border-area {
    border-right: inherit; }
  .about .about-right .experiense-counter {
    left: 15px;
    top: 100px;
    width: 170px; }
    .about .about-right .experiense-counter .counter {
      padding: 15px 20px; }
      .about .about-right .experiense-counter .counter .counter-number h2.title {
        font-size: 50px;
        line-height: 60px; }
      .about .about-right .experiense-counter .counter .counter-number p {
        font-size: 14px; }
  .about .about-right .about-counter .counter {
    margin-bottom: 10px; }
  .video-overlay {
    margin-top: -160px; }
  /*Home 03*/
  .service-section .service-description {
    padding: 20px 0px 0px 0px; }
    .service-section .service-description .section-title {
      margin-bottom: 10px; }
  .clients-section {
    padding: 100px 0; }
  .clients-grid .client-box {
    margin-bottom: 30px; }
  .maintenance-section .maintenance-box {
    padding-right: 0;
    margin-top: 30px; }
  .service-testimonial form {
    padding: 50px; }
  /*Contact Us*/
  .message-info {
    padding: 30px; }
  .contact-form {
    padding-left: 0px;
    margin-top: 30px; }
  .contact-us-form {
    padding-left: 0;
    margin-top: 30px; }
  /*Home*/
  .get-charging .middle-img {
    width: auto; }
  /*Map Listing*/
  .map-listing {
    margin-bottom: 30px; }
    .map-listing .map-icon.icon-01 {
      position: absolute;
      top: 8%;
      left: 8%;
      transform: translateY(-50%); }
    .map-listing .map-icon.icon-02 {
      position: absolute;
      top: -5%;
      left: 60%; }
    .map-listing .map-icon.icon-03 {
      position: absolute;
      top: 18%;
      left: 20%; } }

@media (max-width: 767px) {
  .section-title {
    margin-bottom: 20px; }
    .section-title span {
      font-size: 14px;
      margin-bottom: 10px;
      padding-left: 35px; }
      .section-title span:before {
        width: 25px; }
    .section-title .left-divider span {
      position: relative;
      padding-left: 40px; }
  .get-charging .section-title h2 {
    font-size: 32px; }
  .get-charging .translate-middle {
    position: inherit !important;
    transform: inherit !important;
    margin-bottom: 40px;
    width: 100%; }
  /*workplaces*/
  .workplace-banner .section-title {
    padding: 25% 10% 15% 10%; }
    .workplace-banner .section-title .btn {
      margin-top: 30px; }
  .workplace-banner .workplace-bg {
    height: 300px !important; }
  /*inner-header*/
  .inner-header {
    height: 400px; }
  .inner-header-tittle h2 {
    font-size: 36px;
    line-height: 46px; }
  .about .section-title {
    margin-right: 0; }
  .clients-section {
    padding: 60px 0; }
  .ev-car-section {
    padding-bottom: 60px;
    padding-top: 60px; }
    .ev-car-section .ev-car-area {
      height: inherit; }
      .ev-car-section .ev-car-area .car-area {
        width: 30%; }
        .ev-car-section .ev-car-area .car-area:last-child {
          margin-bottom: 0; }
  .video-overlay {
    margin-top: -100px; }
  .video-image .video-btn {
    width: 70px;
    height: 70px;
    top: 50px; }
    .video-image .video-btn i {
      width: 60px;
      height: 60px;
      font-size: 22px; }
  .service-testimonial form {
    padding: 30px 20px; }
  /*service*/
  .video-bg-section .video-image {
    min-height: 300px; }
  .video-counter .ev-car-area .car-area.car-border-area:nth-child(3) {
    margin-bottom: 0; }
  .about .about-right .about-counter .counter .counter-info {
    width: 100%; }
    .about .about-right .about-counter .counter .counter-info h2 {
      font-size: 46px;
      line-height: 52px; }
  .workplace-banner.software-banner .section-title {
    padding: 10% 5% 10% 5%; }
  .error-404 .error-content .fourofour {
    font-size: 280px; }
  /*Map Listing*/
  .map-listing {
    height: inherit;
    margin-bottom: 40px; }
    .map-listing .map-icon.icon-01 {
      position: absolute;
      top: 4%;
      left: 4%;
      transform: translateY(-50%); }
    .map-listing .map-icon.icon-02 {
      position: absolute;
      top: -21%;
      left: 56%; }
    .map-listing .map-icon.icon-03 {
      position: absolute;
      top: 6%;
      left: 15%; } }

@media (max-width: 575px) {
  .video-counter .ev-car-area .car-area {
    width: 100%; }
  .video-counter .ev-car-area .car-area.car-border-area:nth-child(3) {
    margin-bottom: 30px; }
  .error-404 .error-content .fourofour {
    font-size: 220px; } }

@media (max-width: 479px) {
  /*workplaces*/
  .workplace-banner .section-title {
    padding: 35% 5% 20% 5%; }
  .get-charging .section-title h2 {
    font-size: 30px; }
  /*Contact Us*/
  .message-info {
    padding: 20px; }
    .message-info .feature-item.feature-info-style-03 .feature-icon {
      height: 80px;
      min-width: 80px;
      margin-right: 20px; }
      .message-info .feature-item.feature-info-style-03 .feature-icon object {
        width: 30px; }
  .error-404 .error-content .fourofour {
    font-size: 150px;
    line-height: 180px; }
  .error-404 .error-content img {
    margin-top: -100px; }
  .ev-car-section .ev-car-area .car-area {
    width: 50%; }
  /*Map Listing*/
  .map-listing {
    height: inherit;
    margin-bottom: 40px; }
    .map-listing .map-icon.icon-01 {
      position: absolute;
      top: 0%;
      left: 2%;
      transform: translateY(-50%); }
    .map-listing .map-icon.icon-02 {
      position: absolute;
      top: -30%;
      left: 54%; }
    .map-listing .map-icon.icon-03 {
      position: absolute;
      top: 0%;
      left: 10%; } }

@media (max-width: 375px) {
  /*Map Listing*/
  .map-listing {
    height: inherit;
    margin-bottom: 40px; }
    .map-listing .map-icon.icon-01 {
      position: absolute;
      top: -20%;
      left: -6%;
      transform: translateY(-50%); }
    .map-listing .map-icon.icon-02 {
      position: absolute;
      top: -70%;
      left: 44%; }
    .map-listing .map-icon.icon-03 {
      position: absolute;
      top: -40%;
      left: 2%; } }

/*****************************
    Banner
*****************************/
.banner .banner-sub-title {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex; }

.banner .slider-social {
  display: flex;
  position: absolute;
  z-index: 2;
  top: 50%;
  bottom: 0;
  right: 50px;
  padding-top: 40px;
  text-align: center;
  transform: translate(0px, -50%); }
  .banner .slider-social .slider-social-info ul li a {
    color: #ffffff;
    transform: rotate(-90deg);
    display: inline-block;
    padding: 35px 0px;
    font-size: 20px; }
    .banner .slider-social .slider-social-info ul li a:hover {
      color: #1962a3; }
  .banner .slider-social span {
    display: flex;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    transform: rotate(-90deg);
    text-transform: uppercase;
    align-items: center; }
    .banner .slider-social span:after {
      content: "";
      background: #ffffff;
      width: 30px;
      height: 3px;
      display: inline-flex;
      margin-left: 20px;
      margin-right: 40px;
      align-items: center;
      justify-content: center; }

.banner .swiper-slide {
  height: 950px;
  padding-top: 87px; }
  .banner .swiper-slide .title {
    font-size: 134px;
    line-height: 1; }
  .banner .swiper-slide p {
    font-size: 20px;
    line-height: 28px;
    margin: 20px 0 20px;
    justify-content: space-between; }

.banner .pagination-button .swiper-button-prev {
  background-image: none;
  display: flex;
  color: #ffffff;
  align-items: center;
  top: auto;
  right: 200px;
  bottom: 50px;
  left: auto;
  font-size: 20px;
  transition: all 0.3s ease-in-out; }
  .banner .pagination-button .swiper-button-prev:hover {
    color: #1962a3; }
  .banner .pagination-button .swiper-button-prev:after {
    content: "";
    background: #ffffff;
    width: 110px;
    height: 2px;
    left: 60px;
    position: absolute; }

.banner .pagination-button .swiper-button-next {
  background-image: none;
  display: flex;
  color: #ffffff;
  align-items: center;
  bottom: 50px;
  top: auto;
  left: auto;
  right: 10px;
  transition: all 0.3s ease-in-out;
  font-size: 20px; }
  .banner .pagination-button .swiper-button-next:hover {
    color: #1962a3; }

/*Banner 02*/
.banner.banner-02 .swiper-slide {
  padding-top: 0; }
  .banner.banner-02 .swiper-slide .banner-bg-image {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0; }
  .banner.banner-02 .swiper-slide .banner-sub-title {
    position: absolute;
    left: -200px;
    margin-bottom: 0;
    top: 35px; }
  .banner.banner-02 .swiper-slide p {
    font-size: 22px;
    line-height: 34px; }

.banner.banner-02 .pagination-button .swiper-button-prev {
  left: -80px;
  right: auto; }

.banner.banner-02 .pagination-button .swiper-button-next {
  right: auto;
  left: 110px; }

.banner.banner-02 .slider-social {
  top: 90%;
  bottom: 0;
  right: 0;
  left: 0;
  transform: none;
  padding-top: 10px; }
  .banner.banner-02 .slider-social .slider-social-info {
    text-align: center;
    display: flex;
    justify-content: flex-end; }
    .banner.banner-02 .slider-social .slider-social-info ul {
      display: flex; }
      .banner.banner-02 .slider-social .slider-social-info ul li a {
        transform: none;
        padding: 0px 15px; }

/*Banner 03*/
.banner.banner-03 .swiper-slide .banner-content {
  margin-bottom: 50px;
  margin-left: 50px; }
  .banner.banner-03 .swiper-slide .banner-content .banner-sub-title {
    font-size: 32px; }
  .banner.banner-03 .swiper-slide .banner-content h1 {
    font-size: 130px;
    padding-bottom: 15px; }
    .banner.banner-03 .swiper-slide .banner-content h1:after {
      content: "";
      position: absolute;
      background: #ffffff;
      width: 13%;
      height: 8px;
      left: 0;
      bottom: 0; }

.banner.banner-03 .swiper-slide .banner-content-info p {
  display: flex;
  width: 20%;
  position: absolute;
  top: 250px;
  right: 170px;
  color: #ffffff; }

.banner.banner-03 .pagination-button .swiper-button-prev {
  bottom: 100px; }

.banner.banner-03 .pagination-button .swiper-button-next {
  bottom: 100px; }

@media (max-width: 1800px) {
  .banner.banner-02 .swiper-slide .banner-sub-title {
    position: initial;
    margin-bottom: 20px; }
  .banner.banner-02 .pagination-button .swiper-button-prev {
    left: -40px;
    right: auto; }
  .banner.banner-02 .pagination-button .swiper-button-next {
    left: 150px; } }

@media (max-width: 1300px) {
  .banner.banner-02 .pagination-button .swiper-button-prev {
    left: 0;
    right: auto; }
    .banner.banner-02 .pagination-button .swiper-button-prev::after {
      width: 70px; }
  .banner.banner-02 .pagination-button .swiper-button-next {
    left: 150px; }
  .banner.banner-03 .swiper-slide .banner-content h1 {
    font-size: 80px; }
  .banner.banner-03 .swiper-slide .banner-content-info p {
    width: 30%;
    right: 80px; } }

@media (max-width: 991px) {
  .banner .swiper-slide {
    height: 650px;
    padding-top: 0; }
  .banner .slider-social {
    display: none; }
  .banner.banner-03 .swiper-slide .banner-content .banner-sub-title {
    font-size: 22px; }
  .banner.banner-03 .swiper-slide .banner-content-info p {
    width: 30%;
    top: 170px;
    right: 70px; } }

@media (max-width: 767px) {
  .banner .swiper-slide {
    height: 600px; }
  .banner.banner-02 .swiper-slide {
    height: 700px; }
    .banner.banner-02 .swiper-slide p {
      margin-bottom: 15px;
      padding-right: 0 !important; }
  .banner.banner-02 .pagination-button .swiper-button-prev {
    left: 15px; }
  .banner.banner-02 .pagination-button .swiper-button-next {
    left: 165px; }
  .banner.banner-03 .swiper-slide .banner-content {
    margin-bottom: 100px;
    margin-left: 20px; }
    .banner.banner-03 .swiper-slide .banner-content .banner-sub-title {
      font-size: 18px;
      margin-bottom: 10px; }
    .banner.banner-03 .swiper-slide .banner-content h1 {
      font-size: 60px; }
      .banner.banner-03 .swiper-slide .banner-content h1:after {
        height: 3px; }
  .banner.banner-03 .swiper-slide .banner-content-info p {
    width: 50%;
    top: 150px;
    right: 20px;
    font-size: 16px;
    line-height: 24px; }
  .banner.banner-03 .pagination-button .swiper-button-prev {
    bottom: 20px; }
  .banner.banner-03 .pagination-button .swiper-button-next {
    bottom: 20px; } }

@media (max-width: 575px) {
  .banner .swiper-slide {
    height: 450px; }
  .banner .pagination-button .swiper-button-prev {
    right: 230px; }
  .banner .pagination-button .swiper-button-next {
    right: 40px; }
  .banner.banner-03 .swiper-slide .banner-content {
    margin-left: 0; }
    .banner.banner-03 .swiper-slide .banner-content h1 {
      font-size: 36px; }
  .banner.banner-03 .swiper-slide .banner-content-info p {
    width: 80%;
    right: 15px;
    top: 100px; }
  .banner.banner-03 .pagination-button .swiper-button-prev {
    right: 250px; }
  .banner.banner-03 .pagination-button .swiper-button-next {
    right: 60px; } }

/*****************************
 Blog
*****************************/
.blog-section .owl-carousel .owl-stage-outer {
  padding-top: 15px; }

.blog-post .blog-img {
  position: relative; }
  .blog-post .blog-img img {
    transition: all 0.3s ease-in-out; }

.blog-post .blog-info {
  padding: 30px 50px 0 0; }
  .blog-post .blog-info span {
    font-weight: 500;
    padding-bottom: 10px;
    display: inline-block; }
  .blog-post .blog-info .blog-tittle {
    font-weight: 600;
    margin-bottom: 0; }

.blog-post:hover .blog-img img {
  transform: translateY(-4px); }

/*Blog Style 01*/
.blog-style-01 {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out; }
  .blog-style-01 .blog-img {
    transition: transform 9s cubic-bezier(0.1, 0.2, 0.7, 1); }
    .blog-style-01 .blog-img img {
      transition: all 0.3s ease-in-out; }
    .blog-style-01 .blog-img .blog-badge {
      display: inline-block;
      padding: 5px 18px;
      border-radius: 5px;
      position: absolute;
      left: 30px;
      z-index: 9;
      top: 30px;
      background-color: #1962a3;
      color: #ffffff;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.5px; }
    .blog-style-01 .blog-img .blog-info {
      position: absolute;
      bottom: -50px;
      padding: 30px 30px 0px 30px;
      z-index: 9;
      transition: all 0.3s ease-in-out; }
      .blog-style-01 .blog-img .blog-info p {
        color: #ffffff; }
      .blog-style-01 .blog-img .blog-info h4 {
        color: #ffffff;
        margin-bottom: 0;
        font-weight: 600; }
      .blog-style-01 .blog-img .blog-info p.blog-content {
        opacity: 0;
        transition: all 0.5s ease-in-out;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        margin-bottom: 10px; }
    .blog-style-01 .blog-img:after {
      z-index: 1;
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #091c3a 100%);
      top: auto;
      height: 100%;
      opacity: 0;
      transition: all 0.3s ease-in-out; }
    .blog-style-01 .blog-img:before {
      z-index: 1;
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      background: rgba(0, 16, 34, 0.5);
      top: auto;
      height: 100%;
      transition: all 0.3s ease-in-out; }
  .blog-style-01:hover {
    transform: translateY(-10px); }
    .blog-style-01:hover .blog-img img {
      transform: scale(1.2);
      transition: transform 9s cubic-bezier(0.1, 0.2, 0.7, 1); }
    .blog-style-01:hover .blog-img:after {
      opacity: 1; }
    .blog-style-01:hover .blog-img:before {
      opacity: 0; }
    .blog-style-01:hover .blog-img .blog-info {
      bottom: 20px; }
      .blog-style-01:hover .blog-img .blog-info p.blog-content {
        opacity: 1;
        transform: translateY(0); }

.station-blog .owl-stage-outer {
  padding-top: 25px; }

/*Blog Style 02*/
.blog-post.blog-style-02 .blog-info {
  padding-top: 25px; }
  .blog-post.blog-style-02 .blog-info p {
    margin-bottom: 10px; }
  .blog-post.blog-style-02 .blog-info h4 {
    margin-bottom: 15px; }
  .blog-post.blog-style-02 .blog-info a.blog-link {
    font-weight: 500; }
  .blog-post.blog-style-02 .blog-info .blog-comment span {
    position: relative;
    color: #555555; }
    .blog-post.blog-style-02 .blog-info .blog-comment span:before {
      content: "";
      position: absolute;
      width: 8px;
      height: 3px;
      background: #555555;
      right: 16px;
      top: 12px; }
  .blog-post.blog-style-02 .blog-info .blog-comment a {
    font-weight: 500;
    color: #555555; }
    .blog-post.blog-style-02 .blog-info .blog-comment a:hover {
      color: #1962a3; }

.blog-post.blog-style-02 .blog-info .blog-link:hover {
  color: #091c3a; }

/*Blog Style 03*/
.blog-post.blog-style-03 .blog-info h4 {
  font-size: 32px; }

/*Blog Style Single*/
.blog-post-social {
  display: flex; }
  .blog-post-social span {
    color: #1962a3;
    padding-right: 15px; }
  .blog-post-social ul {
    display: flex;
    margin-bottom: 0; }
    .blog-post-social ul li {
      padding: 0 8px; }
      .blog-post-social ul li a {
        color: #091c3a; }
        .blog-post-social ul li a:hover {
          color: #1962a3; }

.blog-post-navigation .post-navigation .nav-link {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 25px 0;
  margin-top: 50px;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed; }
  .blog-post-navigation .post-navigation .nav-link a i {
    position: absolute;
    font-size: 18px;
    color: #ffffff;
    top: 50%;
    transform: translateY(-50%); }
  .blog-post-navigation .post-navigation .nav-link a span {
    display: block;
    color: #091c3a;
    transition: all 0.5s ease-in-out;
    font-weight: 500; }
  .blog-post-navigation .post-navigation .nav-link a .nav-title {
    font-size: 18px; }
  .blog-post-navigation .post-navigation .nav-link div {
    width: 50%; }
  .blog-post-navigation .post-navigation .nav-link .nav-previous a {
    position: relative;
    display: block;
    padding-left: 80px;
    padding-top: 11px;
    padding-bottom: 11px; }
    .blog-post-navigation .post-navigation .nav-link .nav-previous a i {
      left: 25px; }
    .blog-post-navigation .post-navigation .nav-link .nav-previous a:before {
      position: absolute;
      content: "";
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 60px;
      height: 60px;
      background-color: #1962a3;
      transition: all 0.5s ease-in-out; }
    .blog-post-navigation .post-navigation .nav-link .nav-previous a:hover i {
      color: #ffffff; }
    .blog-post-navigation .post-navigation .nav-link .nav-previous a:hover span {
      color: #1962a3; }
    .blog-post-navigation .post-navigation .nav-link .nav-previous a:hover:before {
      background-color: #091c3a; }
  .blog-post-navigation .post-navigation .nav-link .nav-next {
    text-align: right;
    margin-left: auto; }
    .blog-post-navigation .post-navigation .nav-link .nav-next a {
      position: relative;
      display: block;
      padding-right: 80px;
      padding-top: 11px;
      padding-bottom: 11px; }
      .blog-post-navigation .post-navigation .nav-link .nav-next a i {
        right: 25px; }
      .blog-post-navigation .post-navigation .nav-link .nav-next a:before {
        position: absolute;
        content: "";
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 60px;
        height: 60px;
        background-color: #1962a3;
        transition: all 0.5s ease-in-out; }
      .blog-post-navigation .post-navigation .nav-link .nav-next a:hover i {
        color: #ffffff; }
      .blog-post-navigation .post-navigation .nav-link .nav-next a:hover span {
        color: #1962a3; }
      .blog-post-navigation .post-navigation .nav-link .nav-next a:hover:before {
        background-color: #091c3a; }

.blog-detail .comments-01 {
  display: flex; }
  .blog-detail .comments-01 .comment-author {
    flex: 0 0 90px; }
    .blog-detail .comments-01 .comment-author img {
      width: 100%; }
  .blog-detail .comments-01 .comment-content {
    border: 1px solid #ededed;
    padding: 30px;
    margin-left: 20px;
    margin-bottom: 30px;
    width: 100%; }
    .blog-detail .comments-01 .comment-content .reviews {
      display: flex;
      margin-bottom: 10px;
      align-items: center; }
      .blog-detail .comments-01 .comment-content .reviews .meta {
        font-size: 20px; }
        .blog-detail .comments-01 .comment-content .reviews .meta strong {
          color: #091c3a; }
        .blog-detail .comments-01 .comment-content .reviews .meta span {
          font-weight: 500;
          font-size: 14px; }
      .blog-detail .comments-01 .comment-content .reviews p {
        margin-bottom: 0; }
      .blog-detail .comments-01 .comment-content .reviews .port-social {
        margin-left: auto;
        border-radius: 0;
        color: #1962a3;
        padding: 5px 15px;
        display: block; }

.blog-detail .comments-02 {
  display: flex;
  padding-left: 100px; }
  .blog-detail .comments-02 .comment-author {
    flex: 0 0 90px; }
    .blog-detail .comments-02 .comment-author img {
      width: 100%;
      border-radius: 0; }
  .blog-detail .comments-02 .comment-content {
    border: 1px solid #ededed;
    padding: 30px;
    margin-left: 20px;
    margin-bottom: 30px;
    width: 100%; }
    .blog-detail .comments-02 .comment-content .reviews {
      display: flex;
      margin-bottom: 10px;
      align-items: center; }
      .blog-detail .comments-02 .comment-content .reviews .meta {
        font-size: 20px; }
        .blog-detail .comments-02 .comment-content .reviews .meta strong {
          color: #091c3a; }
        .blog-detail .comments-02 .comment-content .reviews .meta span {
          font-weight: 500;
          font-size: 14px; }
      .blog-detail .comments-02 .comment-content .reviews p {
        margin-bottom: 0; }
      .blog-detail .comments-02 .comment-content .reviews .port-social {
        margin-left: auto;
        border-radius: 0;
        color: #1962a3;
        padding: 5px 15px;
        display: block; }

.blog-detail .leave-reply-form form .form-control {
  border: 1px solid #ededed; }

@media (max-width: 1199px) {
  .blog-section {
    padding: 0 15px; } }

@media (max-width: 991px) {
  .blog-post.blog-style-02 {
    margin-bottom: 30px; }
  /*Blog Style 03*/
  .blog-post.blog-style-03 .blog-info {
    padding: 35px 0 0 0; }
    .blog-post.blog-style-03 .blog-info h4 {
      font-size: 22px;
      font-weight: 600; } }

@media (max-width: 767px) {
  .blog-post.blog-style-02 .blog-info {
    padding-top: 20px;
    padding-right: 0; }
  .blog-post.blog-style-03 .blog-info {
    padding-top: 20px;
    padding-right: 0; } }

@media (max-width: 575px) {
  .blog-detail .video-bg-section .video-image {
    min-height: auto; }
  .blog-detail .comments-01 .comment-content {
    padding: 15px; }
  .blog-detail .comments-02 {
    padding-left: 10px; }
    .blog-detail .comments-02 .comment-content {
      padding: 15px; } }

/*****************************
  Shop
*****************************/
/* Product */
.product .add-to-cart {
  padding-top: 20px; }
  .product .add-to-cart a {
    background: #1962a3;
    color: #ffffff;
    text-transform: capitalize;
    padding: 10px 20px;
    border-radius: 6px;
    display: inline-block; }
    .product .add-to-cart a:hover {
      background: #091c3a; }

.product .product-description .product-title {
  margin: 20px 0px 10px; }
  .product .product-description .product-title a {
    font-size: 16px;
    font-weight: 600;
    color: #091c3a; }
    .product .product-description .product-title a:hover {
      color: #1962a3; }

.product .product-description .product-rating {
  margin-bottom: 10px; }

.product .product-description .product-price {
  font-size: 14px;
  font-weight: bold; }
  .product .product-description .product-price ins {
    color: #1962a3;
    text-decoration: none; }

/* Product Detail */
.shop-single .nav-tabs-02 {
  border: inherit; }
  .shop-single .nav-tabs-02 .nav-tabs {
    border-top: 1px solid #dddddd !important;
    border-bottom: 1px solid #dddddd !important;
    padding: 20px 0 10px 0; }
  .shop-single .nav-tabs-02 .nav-item {
    margin: 0 5px 10px; }
    .shop-single .nav-tabs-02 .nav-item .nav-link {
      font-size: 18px;
      font-weight: 500;
      border-radius: 6px;
      border: inherit;
      color: #091c3a; }
    .shop-single .nav-tabs-02 .nav-item .nav-link.active {
      border: inherit;
      color: #1962a3; }

.shop-single .product-detail .product-price-rating .product-prize {
  margin-bottom: 20px; }
  .shop-single .product-detail .product-price-rating .product-prize p {
    font-size: 28px;
    color: #091c3a;
    font-weight: 500; }
    .shop-single .product-detail .product-price-rating .product-prize p span {
      color: rgba(9, 28, 58, 0.6); }
  .shop-single .product-detail .product-price-rating .product-prize h4 {
    font-size: 20px;
    margin-bottom: 0; }
    .shop-single .product-detail .product-price-rating .product-prize h4 span {
      font-size: 24px;
      color: #1962a3;
      margin-left: 8px; }

.shop-single .product-detail .product-price-rating .product-rating {
  margin: 20px 0; }
  .shop-single .product-detail .product-price-rating .product-rating i {
    color: #1962a3; }

.shop-single .product-detail .add-to-cart-input .form-control {
  height: 54px; }

.shop-single .product-detail .add-to-cart-input .btn {
  text-transform: capitalize; }

.shop-single .product-detail .product-summary a {
  margin-right: 10px;
  color: #1962a3;
  border: 1px solid #091c3a, 0.2;
  border-radius: 0px;
  height: 50px;
  padding: 17px 19px;
  text-align: center;
  justify-content: center;
  display: inline-flex; }
  .shop-single .product-detail .product-summary a i {
    color: #1962a3;
    margin-right: 0px; }
  .shop-single .product-detail .product-summary a:hover {
    color: #091c3a; }

.shop-single .product-detail hr {
  margin: 30px 0; }

.shop-single .product-detail .product-detail-meta {
  margin-bottom: 20px; }
  .shop-single .product-detail .product-detail-meta span {
    display: block;
    margin: 10px 0; }
    .shop-single .product-detail .product-detail-meta span a {
      color: #1962a3; }
      .shop-single .product-detail .product-detail-meta span a:hover {
        color: #091c3a; }

.shop-single .product-detail .input-group {
  width: 90px;
  float: left;
  margin-right: 20px; }

.slider-slick .slider-nav .slick-next:before {
  content: "\f178";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  z-index: 999;
  color: #ffffff;
  font-size: 20px; }

.slider-slick .slider-nav .slick-prev:before {
  content: "\f177";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  z-index: 999;
  color: #ffffff;
  font-size: 20px; }

.slider-slick .slider-nav:hover .slick-prev {
  left: 0;
  z-index: 99;
  opacity: 1;
  border-radius: 0; }

.slider-slick .slider-nav:hover .slick-next {
  right: 0;
  opacity: 1;
  border-radius: 0; }

.slider-slick .slider-nav .slick-prev {
  opacity: 0;
  width: 50px;
  height: 50px;
  background: #1962a3;
  transition: all 0.3s ease; }

.slider-slick .slider-nav .slick-next {
  opacity: 0;
  width: 50px;
  height: 50px;
  background: #1962a3;
  transition: all 0.3s ease; }

.slider-slick .slider-nav .slick-track .slick-slide {
  margin-left: 5px;
  margin-right: 5px; }

/*commentlist*/
.commentlist {
  display: flex; }
  .commentlist .comment-author {
    width: 70px;
    flex: 0 0 70px; }
    .commentlist .comment-author img {
      border-radius: 100%; }
  .commentlist .comment-content {
    padding: 15px;
    margin-left: 30px;
    margin-bottom: 20px;
    width: 100%;
    border: 1px solid #eeeeee; }
    .commentlist .comment-content .reviews {
      display: flex;
      flex-wrap: wrap; }
      .commentlist .comment-content .reviews .rating {
        margin-left: auto; }
        .commentlist .comment-content .reviews .rating i {
          color: #1962a3; }

.form-check-label {
  padding-left: 0px; }

.search-field {
  padding-left: 20px;
  box-shadow: none;
  border: none;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #ededed;
  width: 100%;
  background: #ffffff; }

/* Checkout */
.checkout.checkout-form {
  box-shadow: 0px 1px 30px 0px rgba(9, 28, 58, 0.09);
  padding: 30px; }
  .checkout.checkout-form form .form-control.basic-select {
    color: #8d8c8c; }
  .checkout.checkout-form form .form-control {
    height: 50px; }
  .checkout.checkout-form form textarea.form-control {
    height: auto; }
  .checkout.checkout-form form .select2-container--default .select2-selection--single {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid #ededed; }
    .checkout.checkout-form form .select2-container--default .select2-selection--single .select2-selection__rendered {
      font-size: 14px;
      font-weight: 500; }
    .checkout.checkout-form form .select2-container--default .select2-selection--single .select2-selection__arrow {
      top: 18px; }

.checkout-info {
  padding: 25px 25px 25px 25px;
  position: relative;
  display: flex;
  align-items: center; }
  .checkout-info .returning-icon {
    flex: 0 0 50px;
    margin-right: 20px; }

.checkout-info-returning .checkout-coupon {
  padding: 20px;
  border: 1px solid #ededed;
  border-radius: 4px; }

.checkout-info-coupon .checkout-info {
  display: flex;
  align-items: center; }
  .checkout-info-coupon .checkout-info .coupon-icon {
    flex: 0 0 50px;
    margin-right: 20px; }

.checkout-info-coupon .checkout-coupon {
  padding: 20px;
  border: 2px dashed #ededed;
  border-radius: 4px; }

.checkout-review {
  padding: 15px 30px 30px;
  box-shadow: 0px 1px 30px 0px rgba(9, 28, 58, 0.09); }
  .checkout-review .table tr th {
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    color: #091c3a; }
  .checkout-review .table tr th.shipping {
    font-size: 14px; }
  .checkout-review .table tr td {
    text-align: left;
    padding: 15px 0;
    font-size: 14px;
    font-weight: 500;
    color: #8d8c8c; }
    .checkout-review .table tr td .form-check .form-check-input {
      border-radius: 50%; }
  .checkout-review .table tr td.amount {
    font-size: 18px; }
  .checkout-review .table tbody {
    border-bottom: 2px solid #ededed; }

/* Cart */
.cart-table {
  box-shadow: 0px 1px 30px 0px rgba(9, 28, 58, 0.09); }
  .cart-table .table {
    margin-bottom: 0;
    font-size: 14px; }
    .cart-table .table tr th {
      border: none;
      padding: 15px 10px;
      color: #ffffff;
      background-color: #091c3a;
      text-align: left;
      vertical-align: middle;
      border-radius: inherit !important; }
    .cart-table .table tr td {
      border: none;
      border-top: 1px solid #ededed;
      padding: 15px 10px;
      font-weight: 500;
      text-align: left;
      vertical-align: middle; }
      .cart-table .table tr td.product-remove {
        padding: 15px 25px;
        text-align: center; }
        .cart-table .table tr td.product-remove a {
          color: #ff0000; }
      .cart-table .table tr td.product-quantity .form-control {
        width: 70px;
        border: 1px solid #ededed;
        height: 40px; }
      .cart-table .table tr td a:hover {
        color: #1962a3; }
    .cart-table .table thead tr {
      border: none; }
      .cart-table .table thead tr th:last-child {
        border-radius: 6px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; }
      .cart-table .table thead tr th:first-child {
        border-radius: 6px;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
    .cart-table .table tbody tr td.product-name a {
      color: #091c3a; }
      .cart-table .table tbody tr td.product-name a:hover {
        color: #1962a3; }
  .cart-table .actions {
    display: flex;
    border-top: none;
    padding: 15px 10px;
    align-items: center; }
    .cart-table .actions .coupon form {
      display: flex; }
    .cart-table .actions .coupon .input-text {
      border: 1px solid #ededed;
      border-radius: 0px;
      height: 48px;
      padding: 10px 20px;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-size: 14px;
      font-weight: 500;
      margin-right: 10px;
      color: #091c3a; }
    .cart-table .actions .coupon .btn {
      padding: 12px 20px; }
    .cart-table .actions .update-cart {
      margin-left: auto; }

.cart-table .table td.product-thumbnail img {
  width: 70px;
  height: auto; }

.cart-totals {
  padding: 20px; }
  .cart-totals .cart-totals-title {
    margin-bottom: 20px;
    text-align: center; }
  .cart-totals .table {
    font-size: 14px; }
    .cart-totals .table tr th {
      text-align: left;
      border: none;
      border-top: 1px solid #ededed;
      vertical-align: top;
      background: #ffffff; }
    .cart-totals .table tr td {
      text-align: right;
      border: none;
      border-top: 1px solid #ededed;
      background: #ffffff; }
      .cart-totals .table tr td .form-check .form-check-input {
        border-radius: 50%; }
    .cart-totals .table tr.shipping .form-check-label {
      padding-left: 0px; }
    .cart-totals .table tr.shipping p {
      margin-bottom: 0; }
    .cart-totals .table tr.order-total th {
      font-size: 18px;
      font-weight: 600;
      color: #091c3a; }
    .cart-totals .table tr.order-total td {
      font-size: 26px;
      font-weight: 600;
      color: #091c3a; }
    .cart-totals .table .cart-subtotal th {
      font-size: 18px;
      font-weight: 600;
      border: none; }
    .cart-totals .table .cart-subtotal td {
      font-size: 18px;
      font-weight: 600;
      border: none; }
  .cart-totals .checkout-button {
    width: 100%;
    text-align: center;
    padding: 15px 25px;
    display: block; }

/* Shop Category */
.shop-category {
  padding: 25px 15px; }
  .shop-category h5 {
    margin-bottom: 20px; }
  .shop-category ul {
    margin-bottom: 0; }
    .shop-category ul li a {
      padding: 5px 0;
      display: block; }

.gridlist-toggle-wrap .gridlist-toggle .grid-2-column {
  background-image: url(../images/shop/grid-2-icon.png); }

.gridlist-toggle-wrap .gridlist-toggle .gridlist-button {
  display: inline-block;
  background-color: #dddddd;
  width: 19px;
  height: 19px;
  color: #091c3a;
  font-size: 0;
  line-height: normal !important;
  padding: 0;
  margin-left: 8px;
  text-align: center;
  -webkit-transition: all .3s ease-out 0s;
  -moz-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s; }

.gridlist-toggle-wrap .gridlist-toggle .grid-3-column {
  background-image: url(../images/shop/grid-3-icon.png); }

.gridlist-toggle-wrap .gridlist-toggle .grid-4-column {
  background-image: url(../images/shop/grid-4-icon.png); }

.gridlist-toggle-wrap .gridlist-toggle .gridlist-toggle-list {
  background-image: url(../images/shop/list-icon.png); }

.gridlist-toggle-wrap .gridlist-toggle a:hover, .gridlist-toggle-wrap .gridlist-toggle a.active {
  background-color: #1962a3; }

.shop-single form .form-control {
  border: 1px solid #ededed; }

/* Shop Sidebar */
.shop-sidebar {
  padding: 30px 20px; }
  .shop-sidebar .widget {
    margin-bottom: 50px; }
    .shop-sidebar .widget .widget-title {
      margin-bottom: 30px; }
    .shop-sidebar .widget .price-filter {
      display: flex; }
      .shop-sidebar .widget .price-filter a {
        margin-left: auto; }
    .shop-sidebar .widget .form-check {
      display: block;
      margin-bottom: 0px;
      padding-left: 0; }
      .shop-sidebar .widget .form-check span {
        float: right; }
    .shop-sidebar .widget .social ul li {
      display: inline-block;
      list-style-type: none;
      margin-right: 20px;
      font-size: 16px; }
    .shop-sidebar .widget .tagcloud ul li {
      margin-bottom: 10px;
      display: inline-block; }
      .shop-sidebar .widget .tagcloud ul li a {
        color: #091c3a;
        line-height: 32px;
        border: 1px solid #ededed;
        padding: 0 12px;
        border-radius: 6px;
        transition: all 0.3s ease-in-out;
        display: block; }
        .shop-sidebar .widget .tagcloud ul li a:hover {
          background: #1962a3;
          border: 1px solid #1962a3;
          color: #ffffff; }
    .shop-sidebar .widget .widget-categories ul li a {
      font-size: 14px;
      color: #f0f6fa;
      line-height: 32px;
      display: block; }
      .shop-sidebar .widget .widget-categories ul li a:hover {
        color: #1962a3; }

.irs--flat .irs-line {
  height: 6px; }

.irs--flat .irs-bar {
  background: #1962a3;
  height: 6px; }

.irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single {
  background: transparent;
  color: #1962a3;
  font-size: 13px;
  font-weight: 600; }

.irs--flat .irs-handle > i:first-child, .irs--flat .irs-handle > i:first-child {
  background: #ffffff;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #1962a3;
  cursor: pointer;
  transition: all 0.3s ease-in-out; }

.irs--flat .irs-from:before, .irs--flat .irs-to:before, .irs--flat .irs-single:before {
  display: none; }

.irs--flat .irs-handle.state_hover > i:first-child, .irs--flat .irs-handle:hover > i:first-child {
  background: #1962a3; }

.slider-slick .slider-for .slick-prev, .slider-slick .slider-for .slick-next {
  display: none !important; }

@media (max-width: 1200px) {
  .cart-table .actions .coupon .btn {
    font-size: 14px; }
  .cart-table .actions .update-cart .btn {
    font-size: 14px; } }

@media (max-width: 767px) {
  .slider-slick .slider-nav .slick-prev {
    display: none !important; }
  .slider-slick .slider-nav .slick-next {
    display: none !important; }
  .shop-single .nav-tabs-02 .nav-item .nav-link {
    font-size: 16px; }
  .cart-table .actions {
    display: block; }
  .commentlist .comment-content .reviews p {
    margin-bottom: 5px; }
  .commentlist .comment-content .reviews .rating {
    margin-left: 0;
    margin-bottom: 10px; } }

@media (max-width: 575px) {
  .cart-table .actions {
    display: block; }
    .cart-table .actions .coupon #coupon_code {
      margin-bottom: 10px; }
    .cart-table .actions .coupon .btn {
      margin-bottom: 10px; }
  .shop-single .add-to-cart-input .product-summary, .shop-single .add-to-cart-input .qty {
    margin-bottom: 10px; }
  .shop-single .add-to-cart-input .btn {
    padding: 15px 20px;
    margin-bottom: 10px; }
  .sidebar .cart-totals {
    padding: 25px; } }

@media (max-width: 479px) {
  .cart-table .actions .coupon form {
    display: block; }
    .cart-table .actions .coupon form .input-text {
      width: 100%; }
    .cart-table .actions .coupon form .btn {
      margin-bottom: 0px; } }

/*****************************
    Footer
*****************************/
ul.footer-contact li {
  display: flex;
  margin-bottom: 30px; }
  ul.footer-contact li:last-child {
    margin-bottom: 0; }
  ul.footer-contact li .footer-contact-icon {
    font-size: 36px;
    color: #ffffff;
    margin-right: 15px; }
  ul.footer-contact li .footer-contact-info {
    color: #ffffff; }
    ul.footer-contact li .footer-contact-info span {
      color: #1962a3; }

.footer-newsletter {
  margin-bottom: 70px; }
  .footer-newsletter h2 {
    font-size: 36px; }
  .footer-newsletter p {
    color: #ffffff;
    margin-bottom: 20px; }
  .footer-newsletter .form-inline {
    position: relative; }
    .footer-newsletter .form-inline .form-group {
      width: 100%; }
      .footer-newsletter .form-inline .form-group .form-control {
        width: 100%;
        background: none;
        border: none;
        border-bottom: 1px solid #ffffff;
        color: #ffffff;
        padding: 10px 120px 10px 15px; }
    .footer-newsletter .form-inline .btn-primary {
      position: absolute;
      right: 0px;
      top: 0px;
      background: none;
      color: #1962a3;
      padding: 15px;
      height: 100%; }
      .footer-newsletter .form-inline .btn-primary:hover {
        color: #ffffff; }
    .footer-newsletter .form-inline .subscribe-btn {
      border: none;
      height: 100%; }
      .footer-newsletter .form-inline .subscribe-btn:hover {
        color: #1962a3; }
  .footer-newsletter .form-check .form-check-input {
    background-color: inherit;
    border: 1px solid #ffffff;
    border-radius: 0; }

.useful-links ul li a {
  color: #ffffff;
  line-height: 30px; }
  .useful-links ul li a:hover {
    color: #1962a3;
    opacity: 1; }

.call-center h2 {
  color: #ffffff;
  font-size: 38px;
  margin-bottom: 10px;
  font-weight: 400; }

.call-center h3 {
  color: #ffffff;
  font-size: 22px;
  font-weight: 400; }

.footer-social-icon {
  margin-top: 50px; }
  .footer-social-icon p {
    color: #ffffff;
    font-size: 15px; }
  .footer-social-icon ul {
    display: flex;
    margin-top: 20px; }
    .footer-social-icon ul li {
      margin-right: 20px; }
      .footer-social-icon ul li:last-child {
        margin-right: 0; }
      .footer-social-icon ul li a {
        font-size: 24px;
        color: #ffffff; }
        .footer-social-icon ul li a:hover {
          color: #1962a3; }

.copyright {
  padding: 30px 0px 45px 0px; }
  .copyright .footer-brand img {
    height: 46px; }
  .copyright a {
    color: #ffffff; }
    .copyright a:hover {
      color: #1962a3; }
  .copyright .footer-social-icon {
    margin-top: 0;
    display: flex;
    justify-content: flex-end; }
    .copyright .footer-social-icon ul {
      margin-top: 0; }

.footer-newsletter.newsletter-style-02 form .form-group .form-control {
  background: rgba(255, 255, 255, 0.2);
  border-bottom: none;
  padding: 10px 100px 10px 15px; }

.footer-newsletter.newsletter-style-02 form .form-group input::placeholder {
  color: #ffffff;
  opacity: 0.7; }

.footer-newsletter.newsletter-style-02 form .btn-primary {
  color: #ffffff;
  text-transform: capitalize; }

/*Footer Style 02*/
.footer.footer-style-02 .copyright {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 30px 0;
  margin-top: 40px; }

.footer .footer-logo .logo {
  height: 40px; }

/*************************
       Responsive
*************************/
@media (max-width: 1199px) {
  .footer-newsletter {
    margin-bottom: 30px; }
  .call-center h2 {
    font-size: 34px; }
  .call-center h3 {
    font-size: 20px; }
  .footer-social-icon {
    margin-top: 40px; } }

@media (max-width: 991px) {
  .footer .footer-logo .logo {
    height: 34px; }
  .footer .call-center h2 {
    font-size: 24px; }
  .copyright .footer-social-icon {
    justify-content: center; } }

@media (max-width: 767px) {
  .copyright {
    padding: 60px 0px 35px 0px; } }

@media (max-width: 575px) {
  .footer-social-icon {
    margin-top: 40px; }
  .copyright {
    padding: 40px 0px 20px 0px; }
  .footer.footer-style-02 .copyright {
    margin-top: 0; } }
